<template>
	<div v-if="active" class="bread__crumbs">
		<router-link :to="{name: 'Home'}">Главная</router-link>
		<span></span>
		<p>Проекты</p>
	</div>

	<div v-else-if="politic" class="bread__crumbs">
		<router-link :to="{name: 'Home'}">Главная</router-link>
		<span></span>
		<p>Политика конфиденциальности</p>
	</div>

	<div v-else class="bread__crumbs">
		<router-link :to="{name: 'Home'}">Главная</router-link>
		<span></span>
		<router-link :to="{name: 'Projects'}">Проекты</router-link>
		<span></span>
		<p>{{ project_title }}</p>
	</div>
</template>

<script>
export default {
	name: "BreadCrumbs",
	props: ['project_title'],
	data() {
		return {
			active: null,
			politic: null
		}
	},
	created() {

		if(this.$route.path === '/projects') {
			this.active = true
			this.politic = false
		} else if(this.$route.path === '/politic'){
			this.politic = true
			this.active = false
		} else {
			this.politic = false
			this.active = false
		}

		this.$route.path === '/projects' ? this.active = true : this.active = false
	}
}
</script>

<style scoped lang="scss">
.bread__crumbs {
	@include disflex(flex, stretch, center);
	flex-wrap: wrap;
	margin: 0 0 -8px;

	p, a {
		margin: 0 0 8px;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		color: #8D9BC2;

		&:last-child {
			margin: 0 0 8px;
			color: #0E0252;
		}
	}

	span {
		width: 3px;
		height: 3px;
		background: #8D9BC2;
		border-radius: 50%;
		margin: 0 8px 8px;
	}

}
</style>
