<template>
	<section class="slider">
    <swiper
        :loop="true"
        :slidesPerView="1.9"
        :spaceBetween="50"
        :centeredSlides="true"
        :navigation="{ nextEl: '.button__arrow--next'+genHash, prevEl: '.button__arrow--back'+genHash}"
    >
      <swiper-slide
          class="swiper-slide slider__item"
          v-for="link in info"
          :key="link.id"
          style="cursor:pointer;"
          :style="`background: ${color}`"
      >
        <img :src="require(`@/assets/images/projects/${link.image}`)" alt="">
      </swiper-slide>
    </swiper>
		<div class="button__arrows">
			<div :class="'button__arrow button__arrow--back'+genHash">
				<img src="@/assets/images/slider__arrow__white.svg" alt="">
			</div>
			<div :class="'button__arrow button__arrow--next'+genHash">
				<img src="@/assets/images/slider__arrow__white.svg" alt="">
			</div>
		</div>
	</section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/swiper.min.css'

SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
	name: "Slider",
	props: ["info", "color"],
	data() {
		return {
			slider: null,
      genHash: Math.floor(Math.random() * 10000)
		}
	},
	methods: {

	},
	mounted() {
	}
}
</script>

<style lang="scss">
.slider__item {
	height: 700px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	position: relative;

	img {
		width: 100%;
		height: inherit;
	}

	@media (max-width: 1920px) {
		@include adaptive-elem(height, 700, 210);
	}
}

.slider{
	position: relative;

	$paddingArrow: 32px;

	.button__arrows{
		width: 100%;
		height: 100%;

		.button__arrow{
			width: 58px;
			height: 58px;
			background: #7CB13A;
			border-radius: 50%;
			@include disflex(flex, center, center);
			cursor: pointer;
			box-shadow: 0 2px 4px rgba(0,0,0,0.1);
			img{
				width: 17px;
				height: 32px;

			}

			&:first-of-type, &:last-of-type{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				z-index: 10;
			}

			&:last-of-type{
				right: $paddingArrow;
				img{
					margin: 0 0  0 3px;
				}
			}

			&:first-of-type{
				left: $paddingArrow;
				img{
					transform:rotateY(180deg);
					margin: 0 3px  0 ;
				}
			}
		}
	}
}



</style>
