<template>
  <section v-cloak class="main">
    <div class="main__content mobile__grid" :class="{active: !imageLoad}">
      <div class="main__content__items">
        <div class="main__content__info">
          <h1>Разрабатываем эффективные сайты для <span>бизнеса <span class="circle"></span></span></h1>
          <p class="text">Наши решения всегда подкрепляются базой исследований и опытом разработки</p>
          <ButtonMove
              :text="`Смотреть все проекты`"
              :isWhite="false"
              :image="`arrows`"
              @click="$router.push({name: 'Projects'})"
          />
        </div>
        <div v-if="isImage" class="main__content__video">
          <div v-show="imageLoad">
            <img
                ref="video__container"
                class="video__container"
                :class="{active: imageLoad}"
                :src="require(`@/assets/images/${links.video}`)"
                alt="Main"
                @load="this.imageLoad = true"
            >
          </div>
          <div v-show="!imageLoad">
            <Loader/>
          </div>
        </div>
      </div>
      <div v-if="!isImage" class="main__content__video">
        <div v-show="imageLoad" style="margin: 9px 0 0">
          <img
              style="width: 100%; "
              ref="video__container"
              class="video__container"
              :class="{active: imageLoad}"
              :src="require(`@/assets/images/${links.video}`)"
              alt="Main"
              @load="this.imageLoad = true"
          >
        </div>
        <div v-show="!imageLoad" style="display: flex; align-items: center">
          <Loader/>
        </div>
      </div>

      <div v-if="isImage" class="social__links">
        <a :href="link.href" class="social__item" v-for="link in links.linksForMainSection" :key="link.id">
          <p>{{ link.text }}</p>
          <img src="@/assets/images/btn__arrow__white.svg" alt="Arrow White">
        </a>
      </div>
    </div>
  </section>
</template>


<script>
import ButtonMove from "../../../components/ButtonMove";
import Loader from "../../../components/Loader";

export default {
  name: 'MainSection',
  components: {ButtonMove, Loader},
  props: ['links'],
  data() {
    return {
      isImage: null,
      imageLoad: false
    }
  },
  methods: {
    checkWidth() {
      window.innerWidth <= 976 ? this.isImage = false : this.isImage = true
    },
  },
  mounted() {
    this.checkWidth()
    if (this.$refs.video__container.complete) {
      this.imageLoad = true
    }
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style lang="scss">

@media (max-width: 976px) {
  .mobile__grid {
    &.active {
      display: grid;
      grid-template-rows: auto 300px;
    }
  }
}

.main {
  position: relative;
  background: #0E0252;
  min-height: 100vh;
  z-index: 0;
  @include disflex(flex, stretch, center);

  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 35%;
    height: 100%;
    background: #FFFFFF;
    z-index: -1;
    @media (max-width: 976px) {
      display: none;
    }
    @media (min-width: 1930px) {
      width: 38%;
    }
    @media (min-width: 2200px) {
      width: 44%;
    }
    @media (max-width: 1430px) {
      width: 40%;
    }
    @media (max-width: 1265px) {
      width: 45%;
    }
    @media (max-width: 1100px) {
      width: 35%;
    }
  }

  &__content {
    max-width: 1816px;
    padding: 92px 20px 36px;
    width: 100%;
    @media (min-width: 977px) {
      margin: 0 auto;
    }

    .main__content__video {
      max-width: 862px;
      @include disflex(flex, center, center);
	    @media (max-width: 976px) {
		    max-width: 100%;
	    }

      .video__container {
        width: 100%;

        &.active {
          animation: fade_in 0.2s ease-in-out;
          @keyframes fade_in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }


    .social__links {
      @media (min-height: 600px) {
        position: absolute;
        max-width: 1816px;
        bottom: 0;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 92px 20px 36px;
      }
      @include disflex(flex, stretch, center);
      margin: 40px 0 0;

      .social__item {
        @include disflex(flex, stretch, center);
        margin: 0 64px 0 0;

        p {
          margin: 0 16px 0 0;
          color: #FFFFFF;
        }

        &:last-child {
          margin: 0;
        }
      }
    }


    &__items {
      height: 100%;
      color: white;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 16px;

      @media (max-width: 976px) {
        grid-template-columns: 1fr;
        gap: 0;
      }
      @media (min-width: 976px) {
        margin: 0 auto;
      }


      .main__content__info {
        color: #FFFFFF;

        h1 {
          font-weight: bold;
          font-size: 75px;
          line-height: 84px;
          max-width: 862px;
          @media (max-width: 1920px) {
            @include adaptiv-fontAll(75, 27);
            @include adaptive-elem(line-height, 84, 32);
          }
          @media (max-width: 976px) {
            max-width: 100%;
          }

          span {
            white-space: nowrap;
          }

          .circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #88BA4A;
            display: inline-block;
            @media (max-width: 1920px) {
              @include adaptive-elem(width, 16, 8);
              @include adaptive-elem(height, 16, 8);
            }
          }
        }

        .text {
          font-weight: 600;
          font-size: 25px;
          line-height: 37px;
          margin: 32px 0 64px;
          max-width: 500px;
          @media (max-width: 1920px) {
            @include adaptiv-fontAll(25, 14);
            @include adaptive-elem(line-height, 37, 18);
            @include adaptive-value("margin", 32 19, 0, 64 16, 0);
          }
          @media (max-width: 976px) {
            max-width: 100%;
          }
        }
      }

    }
  }
}
</style>
