<template>
  <div class="project__text">
    <div v-if="ProjectComponents">
      <h4 class="slider__text__h4">Задача:</h4>
      <p class="slider__text__p"> {{ sizeText }}</p>
      <h4 v-show="short_text" class="slider__text__h4">Решение:</h4>
      <p v-show="short_text" class="slider__text__p"> {{ projects.decision }} </p>
    </div>
    <div v-else>
      <h4 class="slider__text__h4">{{ projects.title }}</h4>
      <p v-html="sizeText" class="slider__text__p"></p>
    </div>
    <div
        v-if="mobile"
        class="read__all"
        @click="showAllText"
    >
      <p>{{ textButton }}</p>
      <img :class="{rotate: short_text}" src="@/assets/images/arrow__down__green.svg" alt="arrow down">
    </div>
  </div>
</template>

<script>


export default {
  name: 'ProjectText',
  props: ['projects', 'ProjectComponents'],
  data() {
    return {
      short_text: false,
      mobile: null,
      isHide: false,
      length_short_text: 100
    }
  },
  computed: {
    sizeText() {
      let resultText = this.$props.projects;
      if (resultText.task) return this.smartLengthText(resultText.task)
      else return this.smartLengthText(resultText.text)
    },
    textButton() {
      if (this.short_text) return "Скрыть"
      else return "Читать еще"
    }
  },
  methods: {
    smartLengthText(element) {
      let text;
      if (this.short_text === true) text = element
      else text = element.slice(0, this.length_short_text) + "..."
      return text
    },
    showAllText() {
      this.short_text = !this.short_text
    },
    checkWidth() {
      if (window.innerWidth <= 1600) {
        this.mobile = true
      } else {
        this.short_text = true
        this.mobile = false
      }
    },
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style scoped lang="scss">
.slider__text__h4 {
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  &:first-child{
    margin: 0 0 16px;
  }
  margin: 24px 0 16px;
  @media (max-width: 1920px) {
    @include adaptiv-fontAll(25, 18);
    @include adaptive-elem(line-height, 37, 23);
  }
}

.slider__text__p {
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #0B0B0B;
  margin-bottom: 24px;
  @media (max-width: 1920px) {
    @include adaptiv-fontAll(18, 16);
    @include adaptive-elem(line-height, 28, 24);
  }
}

.read__all {
  @include disflex(flex, stretch, center);
  margin: 8px 0 24px 0;
  cursor: pointer;

  p {
    margin: 0 16px 0 0;
    color: #88BA4A;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
  }

  img {
    transition: 0.2s ease-in-out;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}
</style>
