<template>
	<div class="projects__item"
	     v-for="project in projects"
	     :key="project.id"
	>
		<h3>{{ project.title }}</h3>
		<div class="projects__item__box">

			<div class="image__container" :style="`background: ${project.color}`">
				<img :src="require(`@/assets/images/projects/${project.image}`)" alt="">
			</div>
			<div class="box__text">
				<ProjectText :projects="project.description_project" :ProjectComponents="true"/>
				<ButtonMove
					:text="`Смотреть проект`"
					:isWhite="false"
					:image="`arrows`"
					@click="openProject(project.id)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ButtonMove from "../../components/ButtonMove";
import ProjectText from "../../components/ProjectText";

export default {
	props: ['projects'],
	data() {
		return {
			toContent: 5,
			short_text: null,
		}
	},
	components: {ButtonMove, ProjectText},
	methods: {
		openProject(id) {
			this.$router.push(`/projects/${id + 1}`)
		},
		scrollWindow(e) {
			let pageYOffset = e.path[1].pageYOffset,
				pageHeight = e.srcElement.scrollingElement.scrollHeight,
				visableWindowUser = e.path[1].innerHeight,
				result = pageHeight - (pageYOffset + visableWindowUser);

			if (result <= 100 && this.toContent < this.projects.length) this.toContent++
		},

		scrollTouch(e) {
			let pageHeight = document.querySelector('body').scrollHeight,
				visableWindowUser = e.view.innerHeight,
				pageYOffset = e.view.pageYOffset,
				result = (pageYOffset + visableWindowUser) / pageHeight * 100;
			if (result <= 80 && this.toContent < this.projects.length) this.toContent++
		},
		checkWidth() {
			window.innerWidth <= 1300 ? this.short_text = false : this.short_text = true
		}
	},
	mounted() {
		this.checkWidth()
		window.addEventListener('resize', this.checkWidth)
		window.addEventListener('scroll', this.scrollWindow)
		document.addEventListener('touchmove', this.scrollTouch)
	},
	unmounted() {
		window.removeEventListener('resize', this.checkWidth)
		window.removeEventListener('scroll', this.scrollWindow)
		document.removeEventListener('touchmove', this.scrollTouch)
	}
}
</script>


<style scoped lang="scss">

.button__link {
	margin: 64px 0 0;
	@media (max-width: 1920px) {
		@include adaptive-value("margin", 64 24, 0, 0, 0);
	}
}


.projects__item {
	margin: 80px 0;
	color: #0E0252;

	&:first-child {
		margin: 0 0 80px;
	}

	@media (max-width: 1920px) {
		@include adaptive-value("margin", 80 48, 0, 0, 0);
	}

	h3 {
		font-weight: bold;
		font-size: 48px;
		line-height: 53px;
		margin: 0 0 32px;
		@media (max-width: 1920px) {
			@include adaptive-value("margin", 0, 0, 32 16, 0);
			@include adaptiv-fontAll(48, 25);
			@include adaptive-elem(line-height, 53, 32);
		}
	}

	.image__container {
		@include disflex(flex, center, center);
		border-radius: 5px;
		max-width: 1014px;
		height: 588px;
		overflow: hidden;
		@media (max-width: 1920px) {
			@include adaptive-elem(height, 588, 160);
		}

		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}

	&__box {
		display: grid;
		grid-template-columns: 1fr 0.65fr;
		gap: 50px;
		@media (max-width: 1920px) {
			@include adaptive-elem(gap, 50, 16);
		}
		@media (max-width: 976px) {
			grid-template-columns: 1fr;
			gap: 16px;
		}

		.read__all {
			@include disflex(flex, stretch, center);
			margin: 8px 0 24px;

			p {
				margin: 0 16px 0 0 !important;
				color: #88BA4A;
				font-weight: 600;
				font-size: 15px;
				line-height: 19px;
			}

			img {
				max-width: 14px;
			}
		}

		img {
			width: 100%;
			border-radius: 5px;
		}

		.box__text {
			h4 {
				margin: 0 0 16px;
			}

			.text__section {
				margin: 0 0 24px;

				@media (min-width: 1300px) {
					&:last-child {
						margin: 0;
					}
				}

			}

		}

	}
}
</style>
