<template>
  <section v-if="true" class="stack__popup">
    <div class="stack__popup__content">
      <img @click="CLOSE_POPUP_STACK()" class="close__popup" src="@/assets/images/close.svg" alt="Close">
      <h2>Стек технологий</h2>
      <div class="stack__items">
        <div class="stack__item" v-for="elem in MAIN_PAGE.technologies.stack" :key="elem.id">
          <div class="stack__item__image">
            <img :src="require(`@/assets/images/popup_stack/${elem.image}`)" alt="">
          </div>
          <p>{{elem.text}}</p>
        </div>
      </div>
      <div class="stack__description" v-html="MAIN_PAGE.technologies.description"></div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'StackPopup',
  computed: {...mapGetters(['MAIN_PAGE'])},
  methods: {...mapActions(['CLOSE_POPUP_STACK'])},
}
</script>

<style scoped lang="scss">
.stack__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 10;
  @include disflex(flex, center, center);

  &__content {
    max-width: 710px;
    background: #FFFFFF;
    width: 100%;
    padding: 56px 40px;
    color: #0E0252;
    position: relative;
    overflow-y: auto;
    max-height: 100%;
    border-radius: 5px;
    @media (max-width: 700px) {
      border-radius: 0;
    }
    @media (max-width: 1920px) {
      @include adaptive-value("padding", 56 61, 40 20, 56 30, 40 20);
    }

    .close__popup {
      cursor: pointer;
      position: absolute;
      right: 40px;
      top: 30px;
      @media (max-width: 540px) {
        right: 20px;
        top: 20px;
      }
    }

    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 39px;
      line-height: 51px;
      margin: 0 0 38px;
      @media (max-width: 1920px) {
        @include adaptive-value("margin", 0, 0, 38 24, 0);
        @include adaptiv-fontAll(39, 25);
        @include adaptive-elem(line-height, 51, 32);
      }
    }

    .stack__description{
      margin: 32px 0 0;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #0B0B0B;
    }

    .stack__items {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 24px 48px;
      max-width: 432px;
      margin: 0 auto;
      @media (max-width: 500px) {
        grid-template-columns: auto auto;
      }

      .stack__item {
        @include disflex(flex, stretch, center);

        &__image {
          background: #DCE5FF;
          border-radius: 5px;
          width: 38px;
          height: 38px;
          @include disflex(flex, center, center);

          img {
            width: 100%;
            max-width: 28px;
          }
        }

        p {
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
          margin: 0 0 0 16px;
          @media (max-width: 1920px) {
            @include adaptiv-fontAll(18, 16);
            @include adaptive-elem(line-height, 28, 24);
          }
        }
      }

    }
  }
}

</style>
