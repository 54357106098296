<template>
  <header>
    <div class="header__content">
      <nav class="header__content__nav" :class="{dark: headerBlack, hide: showMenu}">
        <router-link :to="{name: 'Home'}">
          <img v-show="!headerBlack" src="@/assets/images/logo.svg" alt="Logo">
          <img v-show="headerBlack" src="@/assets/images/logoBlue.svg" alt="Logo">
        </router-link>
        <router-link v-if="navDesktop" :to="{name: 'Projects'}">Проекты</router-link>
        <router-link v-if="navDesktop" :to="{name: 'Home', hash: '#team'}">О студии</router-link>
        <router-link v-if="navDesktop" :to="{name: 'Home', hash: '#contacts'}">Контакты</router-link>
      </nav>
      <div v-if="navDesktop" class="header__content__connection">
        <a v-if="phoneDesktop" :href="`tel:${info.phone}`">{{ info.phoneForClient }}</a>
        <ButtonMove
            :text="`Заказать разработку`"
            :isWhite="colorButton"
            :image="`plus`"
            @click="OPEN_POPUP_ORDER()"
        />
      </div>
    </div>
  </header>
</template>

<script>
import {mapActions} from 'vuex';
import ButtonMove from "./ButtonMove";

export default {
  name: 'Header',
  components: {ButtonMove},
  props: ['info', 'navDesktop'],
  data() {
    return {
      headerBlack: false,
      showMenu: false,
      phoneDesktop: null,
      colorButton: null
    }
  },
  methods: {
    ...mapActions(['OPEN_POPUP_ORDER']),
    moveAboutStudio() {
      this.$router.push({name: 'Home', hash: 'about'})
    },
    checkWidth() {
      window.innerWidth <= 1100 ? this.phoneDesktop = false : this.phoneDesktop = true
      window.innerWidth <= 976 ? this.colorButton = false : this.colorButton = true
    },
  },
  watch: {
    $route() {
      document.title = this.$route.meta.title
      this.$route.path !== "/" ? this.headerBlack = true : this.headerBlack = false
    }
  },
  created() {
    this.$route.path !== "/" ? this.headerBlack = true : this.headerBlack = false
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style scoped lang="scss">
$hideNavDesktop: 840px;

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  .header__content {
    max-width: 1816px;
    margin: 16px auto;
    padding: 0 20px;
    @include disflex(flex, space-between, center);

    &__nav a, &__connection a, .order p {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(18, 14);
      }
    }

    &__nav {
      @include disflex(flex, stretch, center);

      &.dark a {
        color: #0E0252;
      }

      &.hide {
        visibility: hidden;
      }

      a, .route {
        color: #FFFFFF;
        margin: 0 32px 0 0;
        position: relative;
        @media (max-width: $hideNavDesktop) {
          display: none;
        }

        &:after {
          position: absolute;
          content: '';
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 6px;
          height: 6px;
          background: #88BA4A;
          border-radius: 50%;
          opacity: 0;
          transition: 0.2s ease-in-out;
        }

        &:hover {


          &:after {
            opacity: 1;
          }
        }

        &:first-of-type {
          margin: 0 55px 0 0;
          display: block;

          &:after {
            display: none;
          }
        }

        &:last-of-type {
          margin: 0;
        }
      }

    }

    &__connection {
      @include disflex(flex, stretch, center);

      a {
        color: #0B0B0B;
        margin: 0 48px 0 0;
        @media (max-width: 1920px) {
          @include adaptive-value("margin", 0, 40 20, 0, 0);
        }
      }

      .order {
        @include disflex(flex, center, center);
        color: #88BA4A;
        border: 1px solid #88BA4A;
        border-radius: 60px;
        padding: 0 30px;
        height: 64px;

        p {
          margin: 0 16px 0 0;
          font-weight: 600;
          font-size: 15px;
          line-height: 19px;
        }

      }

      .call {
        width: 46px;
        height: 46px;
        background: #88BA4A;
        border-radius: 50%;
        @include disflex(flex, center, center);

        img {
          width: 20px;
          height: 20px;
        }
      }


    }

    //.burger {
    //  position: relative;
    //  width: 30px;
    //  height: 18px;
    //  z-index: 4;
    //
    //  &.dark {
    //    span, &:before, &:after {
    //      background: #0E0252;
    //    }
    //
    //    &.active {
    //
    //      span, &:after, &:before {
    //        background: #FFFFFF;
    //      }
    //    }
    //  }
    //
    //  span, &:before, &:after {
    //    position: absolute;
    //    content: '';
    //    background: #FFFFFF;
    //    border-radius: 5px;
    //    height: 2px;
    //    right: 0;
    //    transition: 0.2s ease-in-out;
    //  }
    //
    //  span {
    //    top: 50%;
    //    transform: translateY(-50%);
    //    width: 20px;
    //  }
    //
    //  &:after {
    //    top: 0;
    //    width: 100%;
    //  }
    //
    //  &:before {
    //    bottom: 0;
    //    width: 100%;
    //  }
    //
    //  &.active {
    //
    //    span {
    //      transform: scale(0);
    //    }
    //
    //    &:after {
    //      transform: rotate(-45deg);
    //      top: 8px;
    //    }
    //
    //    &:before {
    //      transform: rotate(45deg);
    //      bottom: 8px;
    //    }
    //  }
    //}
  }
}

</style>
