<template>
  <section class="work">
    <div class="work__content">
      <h2 class="title__section">Как мы работаем</h2>
      <div class="work__content__items">
        <div class="work__compositions">
          <div class="work__composition" v-for="item in works" :key="item.id">
            <div class="composition__count">0{{ item.id + 1 }}.</div>
            <div class="composition__text">
              <div class="composition__title">{{ item.title }}</div>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
        <div class="work__animations">
          <img src="@/assets/images/animate.jpg" alt="">
        </div>
      </div>

      <ButtonMove
          :text="`Стек технологий`"
          :isWhite="true"
          :image="`plus`"
          @click="OPEN_POPUP_STACK()"
      />

    </div>
  </section>
</template>


<script>
import {mapActions} from "vuex";
import ButtonMove from "../../../components/ButtonMove";
export default {
  name: 'Work',
  props: ['works'],
  methods: {...mapActions(['OPEN_POPUP_STACK'])},
  components: {ButtonMove}
}
</script>

<style scoped lang="scss">

.work {

  &__content {
    max-width: 1816px;
    margin: 120px auto 60px;
    padding: 0 20px;

    @media (max-width: 1920px) {
      @include adaptive-value("margin", 120 60, auto, 60 30, auto);
    }

    .button__link {
      margin: 32px 0 0 126px;
      @media (max-width: 976px) {
        margin: 16px 0 0 0;
      }
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 20px;
      @media (max-width: 1600px) {
        grid-template-columns: 1fr 1fr;
      }


      .work__compositions {

        .work__composition {
          color: #0E0252;
          margin: 0 0 32px;
          display: grid;
          grid-template-columns: 94px auto;
          gap: 32px;

          &:last-of-type {
            margin: 0;
          }

          .composition__count {
            font-weight: bold;
            font-size: 58px;
            line-height: 125%;
            color: #6051B0;
            margin: 0 50px 0 0;
            @media (max-width: 1920px) {
              @include adaptiv-fontAll(58, 30);
            }
          }

          .composition__text {
            max-width: 708px;

            .composition__title {
              font-weight: 600;
              font-size: 31px;
              line-height: 40px;
              margin: 0 0 16px;
              @media (max-width: 1920px) {
                @include adaptiv-fontAll(31, 20);
                @include adaptive-value("margin", 0, 0, 16 8, 0);
                @include adaptive-elem(line-height, 40, 26);
              }
            }

            p {
              font-weight: normal;
              font-size: 18px;
              line-height: 28px;
              @media (max-width: 1920px) {
                @include adaptiv-fontAll(18, 16);
                @include adaptive-elem(line-height, 28, 24);
              }
            }
          }
        }

      }

      .work__animations {
        img {
          width: 100%;
        }
      }

      @media (max-width: 976px) {
        grid-template-columns: auto;
        gap: 24px;

        .work__compositions {
          order: 2;

          .work__composition {
            grid-template-columns: 1fr;
            gap: 8px;
            margin: 0 0 24px;
          }
        }
        .work__animations {
          order: 1;
        }
      }
    }

  }
}
</style>
