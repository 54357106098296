<template>
	<div
		ref="buttonRef"
		class="button__link"
		:class="{white: isWhite}"
		@click="$emit('moveClick')"
		@mouseenter="enterMouse"
		@mouseleave="leaveMouse"
	>
		<p>{{ text }}</p>
		<div class="button__link__image" v-if="plus">
			<transition name="fade">
				<img v-if="mouseActive" src="@/assets/images/plus.svg" alt="Plus Green">
				<img v-else src="@/assets/images/plusWhite.svg" alt="Plus White">
			</transition>
		</div>
		<div class="button__link__image" v-if="arrows">
			<transition name="fade">
				<img v-if="mouseActive" src="@/assets/images/btn__arrow__green.svg" alt="Arrow Green">
				<img v-else src="@/assets/images/btn__arrow__white.svg" alt="Arrow White">
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	name: "ButtonMove",
	emits: ['moveClick'],
	props: ['isWhite', 'image', 'text'],
	data() {
		return {
			mouseActive: null,
			plus: null,
			arrows: null
		}
	},
	watch: {
		isWhite(color) {
			if (color) this.mouseActive = true
			else {
				this.mouseActive = false
				this.smartSelectImage()
			}
		}
	},
	methods: {
		enterMouse() {
			if (this.isWhite) this.mouseActive = false
		},
		leaveMouse() {
			if (this.isWhite) this.mouseActive = true
		},
		smartSelectImage() {
			switch (this.$props.image) {
				case 'plus':
					this.plus = true
					break
				case 'arrows':
					this.arrows = true
					break
			}
		}
	},
	mounted() {
		this.isWhite ? this.mouseActive = true : this.mouseActive = false
		this.smartSelectImage()
	},
	unmounted() {
		if (this.mouseActive === "false") this.mouseActive = false
	}
}
</script>

<style scoped lang="scss">

.fade-leave-active {
	transition: none;
	display: none;
}

.fade-v-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
}

.button__link {
  white-space: nowrap;
	user-select: none;
	@include disflex(flex, stretch, center);
	cursor: pointer;
	color: #FFFFFF;
	border: 1px solid #88BA4A;
	border-radius: 60px;
	padding: 0 30px;
	background: #88BA4A;
	width: fit-content;
	height: 64px;
	transition: 0.1s ease-in-out;

	&:hover {
		background: #7CB13A;
	}

	&.white {
		background: #FFFFFF;
		color: #88BA4A;
		border: 1px solid #88BA4A;

		&:hover {
			color: white;
			background: #7CB13A;
		}
	}

	p {
		font-weight: 600;
		font-size: 18px;
		line-height: 23px;
		margin: 0 16px 0 0 !important;
	}

	img {

		max-width: 23px;
	}

	@media (max-width: 1920px) {
		@include adaptive-elem(height, 64, 60);
		p {
			@include adaptiv-fontAll(18, 15);
			@include adaptive-value("margin", 0, 16 10, 0, 0);
			@include adaptive-elem(line-height, 23, 19);
		}
	}
	@media (max-width: 400px) {
		width: 100% !important;
		display: flex !important;
		justify-content: center;
	}
}
</style>
