<template>
	<div class="projects">
		<div class="projects__content">
			<BreadCrumbs/>
			<h2 class="title__section">Проекты</h2>
			<Filter :filter="PROJECTS_ALL" @iSphereActivities="sortByProjects"/>
			<div class="project__items" :class="{active:filter_active }">
				<Project_id :projects="filterProjects"/>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Filter from "./Filter";
import Project_id from "./Project_id";
import BreadCrumbs from "../../components/BreadCrumbs";

export default {
	components: {Filter, Project_id, BreadCrumbs},
	data() {
		return {
			sortProducts: [],
			filter_active: false
		}
	},
	computed: {
		...mapGetters(['PROJECTS_ALL']),
		filterProjects() {
			this.animateFilter()
			if (this.sortProducts.length) {
				return this.sortProducts
			} else {
				return this.PROJECTS_ALL
			}
		}
	},
	methods: {
		sortByProjects(id) {
			this.sortProducts = []
			let vm = this
			this.PROJECTS_ALL.map((item) => {
				if (item.sphere_activities.value === id) {
					vm.sortProducts.push(item)
				}
			})

		},
		animateFilter() {
			this.filter_active = true
			setTimeout(() => {
				this.filter_active = false
			}, 200)
		}
	}
}
</script>


<style lang="scss">
.projects {
	&__content {
		max-width: 1816px;
		margin: 0 auto;
		padding: 145px 20px 105px;
		@media (max-width: 1920px) {
			@include adaptive-value("padding", 145 85, 20, 105 34, 20);
		}

		h2 {
			margin: 16px 0 64px;
			text-align: left;
			@media (max-width: 1920px) {
				@include adaptive-value("margin", 16 30, 0, 64 24, 0);
			}
		}

		.project__items {
			&.active {
				animation: fade_in 0.2s ease-in-out;
				@keyframes fade_in {
					0% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}
		}

	}
}
</style>
