<template>
  <div class="project" v-cloak>
    <div class="project__content">
      <BreadCrumbs :project_title="projectsId.title"/>
      <h2 class="title__section">{{ projectsId.title }}</h2>
      <div v-if="project">
        <StaticImage :color="projectsId.color" :info="projectsId.static_image"/>
        <Description :info="projectsId.description_project"/>
        <h3>Версия для ПК</h3>
        <Slider v-if="desktop" :color="projectsId.color" :info="projectsId.slider_desktop"/>
        <ImageSliderMobile
            v-else
            :text="false"
            :color="projectsId.color"
            :projects="projectsId.slider_desktop"
        />
        <h3>Мобильная версия</h3>
        <Slider v-if="desktop" :color="projectsId.color" :info="projectsId.slider_mobile"/>
        <ImageSliderMobile
            v-else
            :text="false"
            :color="projectsId.color"
            :projects="projectsId.slider_mobile"
        />
        <div :style="`background: ${projectsId.color}`" class="video">
          <img :src="require(`@/assets/images/projects/${projectsId.video}`)" alt="">
          <div class="play">
            <img src="@/assets/images/play.svg" alt="">
          </div>
        </div>
        <SimilarProjects :info="projectsId.another_projects"/>
      </div>

    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../../components/BreadCrumbs";
import StaticImage from "./StaticImage";
import Description from "./Description"
import Slider from "./Slider";
import SimilarProjects from "./SimilarProjects";
import ImageSliderMobile from "../../components/ImageSliderMobile";
import {mapGetters} from "vuex";

export default {
  components: {
    BreadCrumbs,
    StaticImage,
    Description,
    Slider,
    ImageSliderMobile,
    SimilarProjects,
  },
  data() {
    return {
      desktop: null,
      project: []
    }
  },
  computed: {
    ...mapGetters(['PROJECTS_ALL']),
    projectsId() {
      let sortProject = []
      this.PROJECTS_ALL.filter(project => {
        if (project.id === this.$route.params.id - 1) {
          sortProject.push(project)
        }
      })
      return sortProject[0]
    }
  },
  methods: {
    checkWidth() {
      window.innerWidth <= 976 ? this.desktop = false : this.desktop = true
    },
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style lang="scss">
.project {
  &__content {
    max-width: 1816px;
    margin: 0 auto;
    padding: 145px 20px 105px;
    @media (max-width: 1920px) {
      @include adaptive-value("padding", 145 85, 20, 105 34, 20);
    }

    h2 {
      text-align: left;
      margin: 16px 0 64px;
      @media (max-width: 1920px) {
        @include adaptive-value("margin", 16 24, 0, 64 24, 0);
      }
    }

    h3 {
      margin: 80px 0 64px;
      font-weight: bold;
      font-size: 48px;
      line-height: 53px;
      text-align: center;
      color: #0E0252;
      @media (max-width: 1920px) {
        @include adaptive-value("margin", 80 32, 0, 64 16, 0);
        @include adaptiv-fontAll(48, 25);
        @include adaptive-elem(line-height, 53, 32);
      }
    }

    .video {
      margin: 80px 0;
      @include disflex(flex, center, center);
      padding: 170px 230px;
      position: relative;
      border-radius: 5px;
      @media (max-width: 1920px) {
        @include adaptive-value("margin", 80 32, 0, 80 60, 0);
        @include adaptive-value("padding", 170 48, 230 13, 170 48, 230 13);
      }

      img {
        width: 100%;

      }

      .play {
        cursor: pointer;
        position: absolute;
        @include disflex(flex, center, center);
        width: 117px;
        height: 117px;
        background: #88BA4A;
        border-radius: 50%;
        transition: .2s ease-in-out;

        img {
	        width: auto;
          max-width: 49px;
          @media (max-width: 1920px) {
            @include adaptive-elem(max-width, 49, 15);
          }
        }

        &:hover {
          background: #7CB13A;
        }

        @media (max-width: 1920px) {
          @include adaptive-elem(width, 117, 36);
          @include adaptive-elem(height, 117, 36);
        }

      }
    }


  }
}
</style>
