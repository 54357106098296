export default {
    actions: {
        OPEN_POPUP_ORDER({commit}){
            commit('UPDATE_STATE_POPUP_ORDER_OPEN')
        },
        CLOSE_POPUP_ORDER({commit}){
            commit('UPDATE_STATE_POPUP_ORDER_CLOSE')
        },
        OPEN_POPUP_STACK({commit}){
            commit('UPDATE_STATE_POPUP_STACK_OPEN')
        },
        CLOSE_POPUP_STACK({commit}){
            commit('UPDATE_STATE_POPUP_STACK_CLOSE')
        }
    },
    mutations: {
        UPDATE_STATE_POPUP_ORDER_OPEN(state){
            return state.popup.popup_order = true
        },
        UPDATE_STATE_POPUP_ORDER_CLOSE(state){
            return state.popup.popup_order = false
        },
        UPDATE_STATE_POPUP_STACK_OPEN(state){
            return state.popup.popup_stack = true
        },
        UPDATE_STATE_POPUP_STACK_CLOSE(state){
            return state.popup.popup_stack = false
        }
    },
    state: () => ({
        popup: {
            popup_order: false,
            popup_stack: false
        }

    }),
    getters: {
        POPUPS(state) {
            return state.popup
        },
    }
}
