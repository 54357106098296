<template>
  <div class="projects__filter">
    <swiper
        :slidesPerView="'auto'"
        :space-between="spaceSlide"
        :sliderPerGroup="1"
        :freeMode="true"
        :roundLengths="true"
    >
      <swiper-slide
          class="filter__link"
          :class="{active: id_active_filter === 0}"
          @click="activeFilter(0)"
      >
        Все проекты
      </swiper-slide>
      <swiper-slide
          v-for="link in sortFilterId"
          :key="link.id"
          class="filter__link"
          :class="{active: id_active_filter === link.value}"
          @click="activeFilter(link.value)"
      >
        {{ link.text }}
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
// import 'swiper/swiper.scss'
export default {
  props: ['filter'],
  emits: ['filters__projects'],
  data() {
    return {
      id_active_filter: 0,
      spaceSlide: null
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    sortFilterId() {
      let result = this.filter.map(item => item.sphere_activities)

      return result.filter((item, index) => {
        const _item = JSON.stringify(item);
        return index === result.findIndex(obj => {
          return JSON.stringify(obj) === _item;
        });
      })
    }
  },
  methods: {
    activeFilter(id) {
      this.id_active_filter = id
      this.$emit('iSphereActivities', this.id_active_filter)
    },
    checkWidth() {
      window.innerWidth <= 976 ? this.spaceSlide = 16 : this.spaceSlide = 24
    }
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
    if (this.id_active_filter === 0) this.$emit("filters__projects")
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>


<style lang="scss">
@import "./node_modules/swiper/swiper";

.projects__filter {

  .swiper-container {
    z-index: 0;
  }

  .filter__link {
    white-space: nowrap;
    cursor: pointer;
    width: fit-content;
    @include disflex(flex, center, center);
    height: 60px;
    padding: 0 30px;
    border: 1px solid #8D9BC2;
    border-radius: 60px;
    color: #8D9BC2;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    transition: 0.2s ease-in-out;
    @media (max-width: 1920px) {
      @include adaptive-value("margin", 0, 24 16, 80 32, 0);
      @include adaptive-value("padding", 0, 30 20, 0, 30 20);
      @include adaptiv-fontAll(18, 14);
      @include adaptive-elem(line-height, 29, 18);
      @include adaptive-elem(height, 60, 49);
    }

    &.active {
      border: 1px solid #88BA4A;
      color: #88BA4A;
    }
  }
}
</style>
