<template>
  <div class="description">
    <div class="description__item">
      <h4>Задача:</h4>
      <p>{{ info.task }}</p>
    </div>
    <div class="description__item">
      <h4>Решение:</h4>
      <p v-html="info.decision"></p>
    </div>
    <div class="description__item">
      <h4>Технологии:</h4>
      <p v-html="info.technologies"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: "Description",
  props: ['info'],
}
</script>

<style scoped lang="scss">
.description {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px 50px;
  margin: 64px 0 0;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr
  }
  @media (max-width: 1920px) {
    @include adaptive-value("margin", 64 24, 0, 0, 0);
  }

  &__item {
    h4 {
      font-weight: 600;
      font-size: 25px;
      line-height: 37px;
      margin: 0 0 16px;
      color: #0e0252;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(25, 18);
        @include adaptive-elem(line-height, 37, 23);
      }
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #0B0B0B;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(18, 16);
        @include adaptive-elem(line-height, 28, 24);
      }
    }
  }
}
</style>
