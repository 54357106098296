<template>
  <div class="contact__info">

    <a :href="`tel:${info.company.phone}`" class="info__item">
      <img src="@/assets/images/contacts/phone.svg" alt="">
      <p>{{ info.company.phoneForClient }}</p>
    </a>
    <a :href="`mailto:${info.company.email}`" class="info__item">
      <img src="@/assets/images/contacts/mail.svg" alt="">
      <p>{{ info.company.email }}</p>
    </a>
    <div class="info__item">
      <img src="@/assets/images/contacts/location.svg" alt="">
      <p>{{ info.company.address }}</p>
    </div>
    <a
        v-if="!desktop"
        href="https://yandex.ru/maps/org/meta/176210740348/?ll=92.793771%2C56.035317&mode=search&sctx=ZAAAAAgBEAAaKAoSCQ9fJoqQNldAERnG3SBaAUxAEhIJS2z9%2F3%2BN3z8RGWdFsMfmxD8oCjgAQKnNBkgBVc3MzD5YAGIocmVsZXZfcmFua2luZ19jbGlja19mb3JtdWxhPWwzX2ZtbDc1MTI0MGI2cmVsZXZfcmFua2luZ19oZWF2eV9mb3JtdWxhPWwzX2ZtbDk2NTZfZ3VkaW5pX3dpdGhfZGJkYjlyZWFycj1zY2hlbWVfTG9jYWwvR2VvL1JlYXJyYW5nZVJ1YnJpY0J5L1JlbGV2V2VpZ2h0PTAuNjRiOXJlYXJyPXNjaGVtZV9Mb2NhbC9HZW8vUmVhcnJhbmdlUnVicmljQnkvQ2xpY2tXZWlnaHQ9MC4zNmoCcnVwAJ0BzcxMPaABAKgBAL0Bw8kVi8IBBvyI6reQBeoBAPIBAPgBAIICBE1FVEGKAgA%3D&sll=92.793771%2C56.035317&sspn=0.015514%2C0.005135&text=META&z=16"
        class="read__all"
    >
      <p>Смотреть на карте</p>
      <img src="@/assets/images/btn__arrow__green.svg" alt="arrow green">
    </a>
    <div class="info__social">
      <a :href="link.href" class="social__item" v-for="link in info.links" :key="link.id">
        <img :src="require(`@/assets/images/contacts/${link.text.toLowerCase()}.svg`)" alt="">
      </a>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Info',
  props: ['info'],
  data() {
    return {
      desktop: null
    }
  },
  methods: {
    checkWidth() {
      window.innerWidth <= 1200 ? this.desktop = false : this.desktop = true
    }
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style scoped lang="scss">
.contact__info {
  margin: 0 0 0 46px;
  @media (max-width: 1200px) {
    margin: 0;
  }

  .read__all {
    @include disflex(flex, stretch, center);
    margin: 0 0 24px 44px;

    p {
      margin: 0 16px 0 0;
      color: #88BA4A;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
    }

  }

  .info__item {
    @include disflex(flex, stretch, center);
    margin: 0 0 24px;
    color: #0E0252;

    &:nth-child(3) {
      margin: 0 0 32px;
      @media (max-width: 1200px) {
        margin: 0 0 8px;
      }
    }

    img {
      margin: 0 16px 0 0;
    }

    p {
      font-weight: 600;
      font-size: 31px;
      line-height: 40px;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(31, 20);
        @include adaptive-elem(line-height, 40, 26);
      }
    }
  }

  .info__social {
    @include disflex(flex, stretch, center);

    .social__item {
      @include disflex(flex, stretch, center);
      height: 42px;
      width: 42px;
      border-radius: 50%;
      background: #88BA4A;
      justify-content: center;
      margin: 0 32px 0 0;

      @media (max-width: 1920px) {
        @include adaptive-value("margin", 0, 32 24, 0, 0);
        @include adaptive-elem(width, 42, 40);
        @include adaptive-elem(height, 42, 40);
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

}
</style>