<template>
  <div
      ref="map"
      className="map"
  />
  <img ref="icon" src="@/assets/images/Map.svg" alt="" hidden>
</template>


<script>
export default {
  name: 'MapYandex',
  data() {
    return {
      markerCoords: [56.035512, 92.791935],
      coordinates: [56.035512, 92.789535],
      zoom: 18
    }
  },
  created() {
    const yandexMapScript = document.createElement('SCRIPT');
    const link = 'https://api-maps.yandex.ru/2.1/?apikey=a0ffe38b-4988-4cfa-b112-2e6b7d696de0&lang=ru_RU';
    yandexMapScript.setAttribute('src', link);
    yandexMapScript.remove();
    document.head.appendChild(yandexMapScript);
    yandexMapScript.onload = () => {
      ymaps.ready(() => {
        let myMap = new ymaps.Map(this.$refs.map, {
          center: this.coordinates,
          zoom: this.zoom
        })
        let myPlacemark = new ymaps.Placemark(this.markerCoords, {}, {
          iconLayout: 'default#image',
          iconImageHref: this.$refs.icon.src,
          iconImageSize: [64, 85],
          iconImageOffset: [-30, -80]
        })
        myMap.geoObjects.add(myPlacemark);
      });
    };
  },
}
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 620px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
</style>
