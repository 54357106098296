<template>
  <div class="button__up" @click="goUp">
    <img src="@/assets/images/btn__arrow__white.svg" alt="Arrow White">
  </div>
</template>


<script>

export default {
  name: 'ButtonUp',
  methods: {
    goUp () {
      if (window.pageYOffset !== 0) {
        window.scrollBy(0, -60)
        setTimeout(() => {
          this.goUp()
        }, 0);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.button__up{
  cursor: pointer;
  position: absolute;
  top: calc(-57px / 2);
  right: 20px;
  width: 57px;
  height: 57px;
  background: #88BA4A;
  border-radius: 50%;
  @include disflex(flex, center, center);
  img{
    transform: rotate(-90deg);
  }
}


</style>