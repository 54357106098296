<template>
  <div class="wrapper">
    <div class="not__found">
      <div class="not__found__img">
        <img src="@/assets/images/404.svg" alt="">
      </div>
      <div class="not__found__desc">
        <h1>Страница не найдена</h1>
        <ButtonMove
            :text="`Перейти на главную`"
            :isWhite="false"
            :image="`arrows`"
            @click="$router.push({name: 'Home'})"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonMove from "@/components/ButtonMove";

export default {
  components: {ButtonMove},
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px;
  background: #DCE5FF;

  .not__found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__img {
      max-width: 1000px;
      img{
        width: 100%;
        object-fit: contain;
      }
    }
    &__desc{
      h1{
        font-size: 32px;
        color: #0E0252;
        font-weight: bold;
        @media screen and (max-width: 700px) {
          font-size: 24px;
          margin-top: 40px;
        }
      }
      .button__link{
        margin: 24px auto;
      }
    }
  }
}
</style>
