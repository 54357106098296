<template>
  <section class="team" id="team">
    <div class="team__content">
      <h2 class="title__section">О студии</h2>
      <div class="team__content__items">
        <div class="team__text">
          <img v-if="imageChange" src="@/assets/images/team/quotes.svg" alt="">

          <ProjectText :projects="team"  :ProjectComponents="false"/>

        </div>
        <div class="team__image">
          <img class="quotes" v-if="!imageChange" src="@/assets/images/team/quotes.svg" alt="">
          <img src="@/assets/images/team/1.jpg" alt="">
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import ProjectText from "../../../components/ProjectText";

export default {
  name: 'Team',
  props:['team'],
  components:{ProjectText},
  data(){
    return{
      imageChange: null
    }
  },
  methods: {
    checkWidth() {
      window.innerWidth <= 976 ? this.imageChange = false : this.imageChange = true
    }
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style scoped lang="scss">

.team {
  color: #0E0252;

  &__content {
    max-width: 1816px;
    margin: 0 auto;
    padding: 60px 20px 0;
    @media (max-width: 1920px) {
      @include adaptive-value("margin", 0, auto, 0, auto);
      @include adaptive-value("padding", 60 30, 20, 0, 20);
    }

    .slider__text__h4 {
      font-weight: 600;
      font-size: 25px;
      line-height: 37px;
      margin: 24px 0 16px;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(25, 18);
        @include adaptive-elem(line-height, 37, 23);
      }
    }

    .slider__text__p {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #0B0B0B;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(18, 16);
        @include adaptive-elem(line-height, 28, 24);
      }
    }

    .read__all {
      @include disflex(flex, stretch, center);
      margin: 8px 0 0;
      cursor: pointer;

      p {
        margin: 0 16px 0 0;
        color: #88BA4A;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
      }
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 56px;
      @media (max-width: 976px) {
        gap: 30px;
        grid-template-columns: 1fr;
      }

      .team__text {
        display: flex;
        align-items: flex-start;
        @media (max-width: 976px) {
          order: 2;
        }

        img {
          max-width: 81px;
          width: 100%;
          margin: 0 70px 0 0;
        }

        &__item {
          h4 {
            margin: 0 0 32px;
          }
        }

      }

      .team__image {
        @media (max-width: 976px) {
          order: 1;
          position: relative;
        }
        img {
          width: 100%;
        }
        .quotes{
          position: absolute;
          bottom:-20px;
          left: -2px;
          height: 55px;
          width: 63px;
        }
      }
      .read__all {
        @include disflex(flex, stretch, center);
        margin: 8px 0 0;

        p {
          margin: 0 16px 0 0;
          color: #88BA4A;
          font-weight: 600;
          font-size: 15px;
          line-height: 19px;
        }
        img{
          width: 15px;
          height: 7px;
        }
      }
    }
  }
}
</style>
