<template>
	<div class="politic">
		<div class="politic__content">
			<BreadCrumbs/>
			<h2 class="title__section">Политика конфиденциальности</h2>

			<div class="politic__text">
				<div class="politic__text__item" v-for="item in POLITIC" :key="item.id">
					<h3>{{ item.title }}</h3>
					<p v-html="item.text"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import {mapGetters} from "vuex";

export default {
	name: 'Politic',
	components: {BreadCrumbs},
	computed: {...mapGetters(['POLITIC'])}
}
</script>


<style scoped lang="scss">

.politic {

	&__content {
		max-width: 1816px;
		margin: 0 auto;
		padding: 145px 20px 105px;
		@media (max-width: 1920px) {
			@include adaptive-value("padding", 145 85, 20, 105 34, 20);
		}

		h2 {
			margin: 16px 0 64px;
			text-align: left;
			@media (max-width: 1920px) {
				@include adaptiv-fontAll(61, 25);
				@include adaptive-elem(line-height, 67, 32);
				@include adaptive-value("margin", 16 30, 0, 64 16, 0);
			}
		}

		.politic__text {
			max-width: 1160px;
			margin: 0 auto;

			&__item {
				margin: 48px 0;

				&:first-child {
					margin: 0 0 48px 0;
				}

				&:last-of-type {
					margin: 48px 0 0 0;
				}

				@media (max-width: 1920px) {
					@include adaptive-value("margin", 48 24, 0, 48 24, 0);
					&:first-child {
						@include adaptive-value("margin", 0, 0, 48 24, 0);
					}

					&:last-of-type {
						@include adaptive-value("margin", 48 24, 0, 0, 0);
					}
				}

				h3 {
					font-weight: 600;
					font-size: 31px;
					line-height: 40px;
					color: #0E0252;
					margin: 0 0 16px;
					@media (max-width: 1920px) {
						@include adaptive-value("margin", 0, 0, 16 8, 0);
						@include adaptiv-fontAll(31, 18);
						@include adaptive-elem(line-height, 31, 23);
					}
				}

				p {
					font-weight: normal;
					font-size: 18px;
					line-height: 28px;
					color: #0B0B0B;
					@media (max-width: 1920px) {
						@include adaptiv-fontAll(18, 16);
						@include adaptive-elem(line-height, 28, 24);
					}
				}
			}


		}
	}

}
</style>
