import {createStore} from 'vuex'
import mainPage from "./mainPage";
import projects from "./projects";
import popup from "./popup";
import form from "./form";
import politic from "@/store/politic";

export default function() {
    return createStore({
        modules: {
            mainPage,
            projects,
            popup,
            form,
            politic
        }
    })
}