<template>
	<section class="projects">
		<div class="projects__content">
			<h2 class="title__section">Проекты</h2>
			<SliderDesktop
				v-if="sliderDesktop"
				:projects="projects"
			/>
			<ImageSliderMobile
				v-if="!sliderDesktop"
				:text="true"
				:projects="projects"
			/>
		</div>
	</section>
</template>


<script>
import ImageSliderMobile from "../../../components/ImageSliderMobile";
import SliderDesktop from "@/views/home/v-projects/SliderDesktop";

export default {
	name: 'Projects',
	props: ['projects'],
 components: {ImageSliderMobile, SliderDesktop},
	data() {
		return {
			sliderDesktop: null,
		}
	},

	methods: {
		checkWidth() {
			window.innerWidth <= 1600 ? this.short_text = false : this.short_text = true
			window.innerWidth <= 976 ? this.sliderDesktop = false : this.sliderDesktop = true
		},
	},
	mounted() {
		this.checkWidth()
		window.addEventListener('resize', this.checkWidth)
	},
	unmounted() {
		window.removeEventListener('resize', this.checkWidth)
	}
}
</script>


<style lang="scss">
.first {
	&.active {
		animation: jump 0.4s ease-in;
	}
}

.projects {
	&__content {
		max-width: 1816px;
		margin: 80px auto 60px;
		padding: 0 20px;
		color: #0E0252;

		@media (max-width: 1920px) {
			@include adaptive-value("margin", 80 30, auto, 60 30, auto);
		}
	}

	.slider__mobile {
		.swiper-container {
			.swiper-slide {
				height: 540px;
				@media (max-width: 976px) {
					@include adaptive-elem-mobile(height, 540, 160);
				}
			}
		}
	}
}
</style>
