
export default {
	actions: {
	},
	mutations: {
	},
	state: () => ({
		politic: [
			{
				title: "1. Общее положение",
				text:"Настоящие Положение о политике конфиденциальности (далее — Положение) является официальным документом ООО «Банзай», расположенного по адресу: г. Красноярск, ул. Калинина, 53а стр. 19 (далее — «Компания» / «Оператор»), и определяет порядок обработки и защиты информации о физических лицах (далее — Пользователи), пользующихся сервисами, информацией, услугами, программами (в т. ч. программами лояльности) и продуктами интернет-магазина, расположенного на доменном имени www.meta.studio (далее — Сайт).\n" +
					"\n" +
					"Мы разработали Политику Конфиденциальности, которая описывает, как мы осуществляем обработку персональных данных — любые действия (операции) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. Настоящие Положение о политике конфиденциальности (далее — Положение) является официальным документом ООО «Банзай», расположенного по адресу: г. Красноярск, ул. Калинина, 53а стр. 19 (далее — «Компания» / «Оператор»), и определяет порядок обработки и защиты информации о физических лицах (далее — Пользователи), пользующихся сервисами, информацией, услугами, программами (в т. ч. программами лояльности) и продуктами интернет-магазина, расположенного на доменном имени www.meta.studio (далее — Сайт).\n" +
					"\n" +
					"Мы разработали Политику Конфиденциальности, которая описывает, как мы осуществляем обработку персональных данных — любые действия (операции) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. \n" +
					"\n"
			},
			{
				title: "2. Сбор персональных данных",
				text: "Целью обработки персональных данных является выполнения обязательств Оператора перед Пользователями в отношении использования Сайта и его сервисов.\n" +
					"\n" +
					"Обработка персональных данных пользователей осуществляется с согласия субъекта персональных данных на обработку его персональных данных.\n" +
					"\n" +
					"Под персональными данными понимается любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных) и которая может быть использована для идентификации определенного лица либо связи с ним.\n" +
					"\n" +
					"Мы можем запросить у Вас персональные данные в любой момент, когда Вы связываетесь с Компанией. Компания может использовать такие данные в соответствии с настоящей Политикой Конфиденциальности. Она также может совмещать такую информацию с иной информацией для целей предоставления и улучшения своих продуктов, услуг, информационного наполнения (контента) и коммуникаций.\n" +
					"\n" +
					"Ниже приведены некоторые примеры типов персональных данных, которые Компания может собирать, и как мы можем использовать такую информацию.\n"
			},
			{
				title: "3. Хранение и использование персональных данных",
				text: "Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.\n" +
					"\n" +
					"Как мы используем вашу персональную информацию\n" +
					"\n" +
					"Собираемые нами персональные данные позволяют направлять Вам уведомления о новых продуктах, специальных предложениях и различных событиях. Они также помогает нам улучшать наши услуги, контент и коммуникации. Если Вы не желаете быть включенным в наш список рассылки, Вы можете в любое время отказаться от рассылки путём информирования нас по указанным контактам для обратной связи, а также внесения изменений в настройках вашего профиля на сайте.\n" +
					"\n" +
					"Время от времени мы можем использовать Ваши персональные данные для отправки важных уведомлений, содержащих информацию об изменениях наших положений, условий и политик, а также подтверждающих размещенные Вами заказы и совершенные покупки. Поскольку такая информация важна для Ваших взаимоотношений с Компанией, Вы не можете отказаться от получения таких сообщений.\n" +
					"\n" +
					"Мы также можем использовать персональную информацию для внутренних целей, таких как: проведение аудита, анализ данных и различных исследований в целях улучшения продуктов и услуг Компании, а также взаимодействие с потребителями."
			},
			{
				title: "4. Передача персональных данных",
				text: "Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.\n" +
					"\n" +
					"Как мы используем вашу персональную информацию\n" +
					"\n" +
					"Собираемые нами персональные данные позволяют направлять Вам уведомления о новых продуктах, специальных предложениях и различных событиях. Они также помогает нам улучшать наши услуги, контент и коммуникации. Если Вы не желаете быть включенным в наш список рассылки, Вы можете в любое время отказаться от рассылки путём информирования нас по указанным контактам для обратной связи, а также внесения изменений в настройках вашего профиля на сайте.\n" +
					"\n" +
					"Время от времени мы можем использовать Ваши персональные данные для отправки важных уведомлений, содержащих информацию об изменениях наших положений, условий и политик, а также подтверждающих размещенные Вами заказы и совершенные покупки. Поскольку такая информация важна для Ваших взаимоотношений с Компанией, Вы не можете отказаться от получения таких сообщений.\n" +
					"\n" +
					"Мы также можем использовать персональную информацию для внутренних целей, таких как: проведение аудита, анализ данных и различных исследований в целях улучшения продуктов и услуг Компании, а также взаимодействие с потребителями."
			},
			{
				title: "5. Уничтожение персональных данных",
				text: "Персональные данные пользователя уничтожаются при: <br> удалении Оператором информации, размещаемой Пользователем, а также персональной страницы Пользователя в случаях, установленных договором купли продажи (оферта); <br> при отзыве субъектом персональных данных согласия на обработку персональных данных."
			},
			{
				title: "6. Защита персональных данных",
				text: "Компания предпринимает меры предосторожности — включая правовые, организационные, административные, технические и физические — для обеспечения защиты Ваших персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.\n" +
					"\n" +
					"Целостность и сохранение персональной информации\n" +
					"\n" +
					"Взаимодействуя с Компанией, Вы можете легко поддерживать свои персональные данные и информацию в актуальном состоянии. Мы будем хранить Ваши персональные данные и информацию в течение срока, необходимого для выполнения целей, описываемых в настоящей Политике Конфиденциальности, за исключением случаев, когда более длительный период хранения данных и информации необходим в соответствии с законодательством либо разрешён им.\n" +
					"\n" +
					"Соблюдение вашей конфиденциальности на уровне компании\n" +
					"\n" +
					"Для того чтобы убедиться, что Ваши персональные данные находятся в безопасности, мы доводим нормы соблюдения конфиденциальности и безопасности до работников Компании и строго следим за исполнением мер соблюдения конфиденциальности внутри Компании.\n" +
					"\n" +
					"Вопросы относительно конфиденциальности\n" +
					"\n" +
					"Если у вас возникнут вопросы в отношении Политики Конфиденциальности Компании или обработки данных Компанией, Вы можете связаться с нами по контактам для обратной связи."
			},
			{
				title: "7. Обращения пользователей",
				text: "К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором применяется действующее законодательство РФ.\n" +
					"\n" +
					"Пользователи вправе направлять Оператору свои запросы, в том числе запросы относительно использования их персональных данных, направления отзыва согласия на обработку персональных данных в письменной форме по адресу, указанному разделе 1 настоящего положения, или в форме электронного документа, подписанного квалифицированной электронной подписью в соответствии с законодательством РФ, и отправленного по средствам формы обратной связи.\n" +
					"\n" +
					"Оператор обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в течение 30 дней с момента поступления обращения."
			},
			{
				title: "8. Прочие условия",
				text: "Во всем остальном, что не отражено напрямую в Политике Конфиденциальности, Компания обязуется руководствоваться нормами и положениями Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных»\n" +
					"\n" +
					"Посетитель Сайта Компании, предоставляющий свои персональные данные и информацию, тем самым соглашается с положениями данной Политики Конфиденциальности."
			}
		],
	}),
	getters: {
		POLITIC(state) {
			return state.politic
		},
	}
}
