<template>
	<div class="projects__content__slider">
		<div class="slider__watch">
			<div class="swiper-container slider__image " ref="sliderForProjects">
				<div class="swiper-wrapper">
					<div class="swiper-slide"
					     v-for="image in projects"
					     :key="image.id"
					     :style="`background: ${image.color}`"
					>
						<img
							:src="require(`@/assets/images/projects/${image.image_main_slider}`)"
							alt=""
						>
					</div>
				</div>
			</div>
			<div class="slider__setup">
				<div class="slider__count">
					<p :class="{active: isAnimateCount}">{{ id_slide + 1 }}</p>
					<p>/</p>
					<p>{{ all_slide }}</p>
				</div>
				<div class="slider__arrows" :style="`pointer-events: ${eventsButton};`">
					<div class="slider__arrow" :class="{grey: !backMove}" @click="backMoveClick">
						<img v-if="backMove" src="@/assets/images/btn__arrow__white.svg" alt="">
						<img v-else src="@/assets/images/btn__arrow__grey.svg" alt="">
					</div>
					<div class="slider__arrow" :class="{grey: !prevMove}" @click="prevMoveClick">
						<img v-if="prevMove" src="@/assets/images/btn__arrow__white.svg" alt="">
						<img v-else src="@/assets/images/btn__arrow__grey.svg" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="slider__text">
			<h3 class="slider__text__h3">{{ projects[id_slide].title }}</h3>
			<ProjectText :projects="projects[id_slide].description_project" :ProjectComponents="true"/>
			<div class="slider__buttons">
				<ButtonMove
					:text="`Смотреть проект`"
					:isWhite="false"
					:image="`arrows`"
					@click="$router.push({name: 'ProjectsId', params: {id: id_slide + 1}})"
				/>
				<ButtonMove
					:text="`Смотреть все проекты`"
					:isWhite="true"
					:image="`arrows`"
					@click="$router.push({name: 'Projects'})"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ButtonMove from "../../../components/ButtonMove";
import ProjectText from "../../../components/ProjectText";
import Swiper from 'swiper';

export default {
	name: "SliderDesktop",
	components: {ButtonMove, ProjectText},
	props: ['projects'],
	data() {
		return {
			backMove: false,
			prevMove: true,
			short_text: null,
			id_slide: 0,
			all_slide: this.$props.projects.length,
			isAnimateCount: false,
			eventsButton: 'auto',
			sliderProjects: null,
			mySliderOptions: {
				slidesPerView: 1,
				spaceBetween: 30
			},
		}
	},
	computed: {
		sizeText() {
			if (this.short_text === true) {
				return this.$props.projects[this.id_slide].task
			} else {
				return this.$props.projects[this.id_slide].task.slice(0, 100) + "..."
			}
		},
	},
	watch: {
		id_slide(slide) {
			slide + 1 <= 1 ? this.backMove = false : this.backMove = true
			slide + 1 === this.all_slide ? this.prevMove = false : this.prevMove = true
			this.animateCount()
		}
	},
	methods: {
		backMoveClick() {
			if (this.id_slide + 1 > 1) this.sliderProjects.slidePrev()
		},
		prevMoveClick() {
			if (this.id_slide + 1 < this.all_slide) this.sliderProjects.slideNext()
		},
		animateCount() {
			this.isAnimateCount = true
			setTimeout(() => {
				this.isAnimateCount = false
			}, 400)
		},
	},
	mounted() {
		this.sliderProjects = new Swiper(this.$refs.sliderForProjects, this.mySliderOptions)
		this.sliderProjects.on('slideChange', (e) => {
			this.id_slide = e.realIndex
		})
	},
}
</script>


<style scoped lang="scss">
@import "node_modules/swiper/swiper";
.projects__content__slider {
	display: grid;
	grid-template-columns: 1014px auto;
	gap: 50px;
	width: 100%;
	@media (max-width: 1920px) {
		@include adaptive-elem(gap, 50, 16);
	}
	@media (max-width: 1700px) {
		grid-template-columns: 900px auto;
	}
	@media (max-width: 1580px) {
		grid-template-columns: 800px auto;
	}
	@media (max-width: 1400px) {
		grid-template-columns: 55% auto;
	}
	@media (max-width: 976px) {
		display: block;
	}

	.slider__watch {

		.swiper-container {
			max-height: 588px;
			border-radius: 5px;
			max-width: 1014px;
			position: relative;
		}

		.slider__image {
			.swiper-slide {
				height: 100%;
				overflow: hidden;

				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}
		}

		.slider__setup {
			margin: 32px 0 0;
			@include disflex(flex, space-between, center);

			.slider__count {
				@include disflex(flex, stretch, flex-end);

				p {
					font-weight: 600;
					font-size: 25px;
					line-height: 37px;
					color: #0E0252;


					&:first-of-type {
						font-weight: bold;
						font-size: 48px;
						line-height: 53px;

						&.active {
							animation: jump 0.2s ease-in;
						}
					}

					&:nth-child(2) {
						margin: 0 5px;
					}
				}
			}
		}
	}

	.slider__arrows {
		user-select: none;
		@include disflex(flex, stretch, center);

		.slider__arrow {
			cursor: pointer;
			width: 57px;
			height: 57px;
			border-radius: 50%;
			background: #88BA4A;
			@include disflex(flex, center, center);
			transition: .2s ease-in-out;

			&:hover {
				background: #7CB13A;
			}

			&.grey {
				background: #EAF1F3;
			}

			img {
				width: 28px;
			}

			&:first-of-type {
				transform: rotate(180deg);
				margin: 0 24px 0 0;
			}
		}
	}

	.slider__text {
    display: flex;
    flex-direction: column;
		.slider__text__h3 {
			font-weight: 600;
			font-size: 39px;
			line-height: 51px;
			margin: 0 0 24px;
			@media (max-width: 1920px) {
				@include adaptiv-fontAll(39, 25);
				@include adaptive-elem(line-height, 51, 32);
			}
		}


	}
	.slider__buttons {
		@include disflex(flex, stretch, center);
		margin: auto 0 0;
		@media (max-width: 1530px) {
			display: block;
		}

		.button__link {
			padding: 0 30px;

			&:first-of-type {
				margin: 0 32px 0 0;
				@media (max-width: 1530px) {
					margin: 0 0 32px;
				}
			}
		}
	}
}

</style>
