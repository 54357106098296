<template>
  <section class="navigation">
    <div class="navigation__content">
      <router-link :to="{name: 'Projects'}" class="navigation__content__item">
        <img src="@/assets/images/navigation/projects.svg" alt="">
        <p>Проекты</p>
      </router-link>
      <router-link :to="{name: 'Home', hash: '#team'}" class="navigation__content__item">
        <img src="@/assets/images/navigation/meta.svg" alt="">
        <p>О студии</p>
      </router-link>
      <router-link :to="{name: 'Home'}" class="navigation__content__item">
        <img src="@/assets/images/navigation/home.svg" alt="">
      </router-link>
      <router-link :to="{name: 'Home', hash: '#contacts'}" class="navigation__content__item">
        <img src="@/assets/images/navigation/location.svg" alt="">
        <p>Контакты</p>
      </router-link>
      <div @click="OPEN_POPUP_ORDER()" class="navigation__content__item">
        <img src="@/assets/images/navigation/order.svg" alt="">
        <p>Заявка</p>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'Navigation',
  methods: {...mapActions(['OPEN_POPUP_ORDER'])},
}
</script>

<style scoped lang="scss">
.navigation{
  display: none;
}
@media screen and (max-width: 840px) {
  .navigation {
    display: block;
    z-index: 5;
    user-select: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 2px 10px #0E025210;

    &__content {
      padding: 8px 24px;
      @include disflex(flex, space-between, center);

      &__item {
        cursor: pointer;

        img {
          margin: 0 0 4px;
        }

        p {
          color: #8D9BC2;
          font-weight: normal;
          @include adaptiv-fontAll(16, 8);
          line-height: 10px;
        }

        text-align: center;

        &:nth-child(3) {
          @include disflex(flex, center, center);
          background: #7CB13A;
          width: 40px;
          height: 40px;
          border-radius: 50%;

          img {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
