<template>
	<Header v-if="$router.currentRoute.value.name !== 'not-found'" :info="MAIN_PAGE.contacts.company" :navDesktop="navDesktop"/>
	<router-view v-slot="{ Component }">
		<component :is="Component"/>
	</router-view>
	<Footer v-if="$router.currentRoute.value.name !== 'not-found'"/>
	<transition name="popup">
		<section v-if="POPUPS.popup_order" class="popup__form">
			<Form :popup="true"/>
		</section>
	</transition>
	<transition name="popup">
		<Stack_Popup v-if="POPUPS.popup_stack"/>
	</transition>
	<!--	<Cookie/>-->
	<Navigation v-if="$router.currentRoute.value.name !== 'not-found'"/>
</template>


<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Form from "./components/Form";
import Stack_Popup from "./components/Stack_Popup";
// import Cookie from "./components/Cookie";
import {mapGetters} from "vuex";
import Navigation from "@/components/Navigation";
import "@/assets/scss/_null.scss";
import "@/assets/scss/fonts.scss";
import "@/assets/scss/template.scss";
import router from "./router";

export default {
	data() {
		return {
			navDesktop: null
		}
	},
	components: {
		Header,
		Footer,
		Form,
		Stack_Popup,
		Navigation
	},
	computed: {...mapGetters(['POPUPS', 'MAIN_PAGE'])},
	methods: {
		checkWidth() {
			window.innerWidth <= 840 ? this.navDesktop = false : this.navDesktop = true
		},
	},
	mounted() {
    this.checkWidth()
		window.addEventListener('resize', this.checkWidth)
	},
	unmounted() {
		window.removeEventListener('resize', this.checkWidth)
	}
}
</script>


<style lang="scss">


body {
	font-family: Inter, sans-serif;
	position: relative;
	overflow-x: hidden;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@keyframes jump {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}

}

@keyframes left {
	0% {
		opacity: 0;
		transform: translateX(-40px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes right {
	0% {
		opacity: 0;
		transform: translateX(40px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fade-enter-active, .fade-leave-active {
	transition: 0.2s ease-in-out;
}


.fade-enter-from {
	opacity: 0;
	animation: fade-out ease-in-out;
	@keyframes fade_out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

.fade-leave-from {
	opacity: 0;
	animation: fade-in ease-in-out;
	@keyframes fade_in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}

.popup-enter-active, .popup-leave-to {
	opacity: 0;
}

.popup-enter-to {
	animation: show .2s ease-in-out;
	@keyframes show {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	form, .stack__popup__content {
		animation: jumpDown .2s ease-in-out;
		@keyframes jumpDown {
			0% {
				transform: translateY(-20px);
			}
			100% {
				transform: translateY(0);
			}
		}
	}
}

.popup-leave-active {
	transition: .2s ease-in-out;

	form, .stack__popup__content {
		animation: jumpUp .2s ease-in-out;
		@keyframes jumpUp {
			0% {
				transform: translateY(0);

			}
			100% {
				transform: translateY(-20px);
			}
		}
	}
}

#app {
	display: grid;
	grid-template:  1fr auto / 100%;
	min-height: 100vh;
}

.popup__form {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .6);
	z-index: 10;
	@include disflex(flex, center, center);

	form {
		background: #FFFFFF;
		color: #0E0252;
		overflow-y: auto;
		max-height: 100%;

		.form__item {
			input, textarea {
				border-bottom: 1px solid #0E0252;
				color: #0E0252;

				&::placeholder {
					color: #0E0252;
				}
			}

		}

		.agreement, .agreement a {
			color: #8D9BC2;
		}

		.agreement a {
			border-bottom: 1px solid #8D9BC2
		}

	}
}


</style>
