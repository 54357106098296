import {createRouter} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/home'),
        meta: {title: 'META'}
    },
    {
        path: '/projects',
        name: 'Projects',
        component: () => import('../views/projects/index'),
        meta: {title: 'META - PROJECTS'}
    },
    {
        path: '/projects/:id',
        name: 'ProjectsId',
        component: () => import('../views/project_id/index'),
        meta: {title: `META - PROJECT`}
    },
    {
        path: '/politic',
        name: 'Politic',
        component: () => import('../views/Politic'),
        meta: {title: `META - POLITIC`}
    },
    {path: '/:pathMatch(.*)*', name: 'not-found', component: () => import('../views/NotFound')}
];

export default function (history) {
    return createRouter({
        history,
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else if (to.hash) {
                const options = {
                    top: document.querySelector(to.hash).offsetTop,
                    behavior: 'smooth'
                };
                window.scrollTo(options);

            } else {
                window.scrollTo(0, 0);
            }
        }
    })
}
