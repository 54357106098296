<template>
  <div class="error__validate">
    <div class="error__image">
      <img src="@/assets/images/validate_error.svg" alt="">
    </div>
    <p>{{ error }}</p>
  </div>
</template>


<script>

export default {
  name: 'ErrorValidate',
  props: ['error'],
}
</script>

<style scoped lang="scss">
.error__validate {
  position: absolute;
  top: calc(100% + 8px);
  @include disflex(flex, stretch, center);
  padding: 4px 8px 4px 4px;
  background: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0 2px 6px  rgba(0, 0, 0, 0.1);


  .error__image {
    @include disflex(flex, center, center);
    background: #E43F3F;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 0 4px 0 0;

    img {
      max-width: 3px;
    }
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #DA2323;
  }
}

</style>