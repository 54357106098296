<template>
  <div class="static__image">
    <div class="static__image__background" :style="`background: ${color}`">
      <img class="image" :src="require(`@/assets/images/projects/${info.image}`)" alt="">
      <div v-if="desktop" class="static__image__text" :class="{dark_color: info.darkMode}">
        <p>Ссылка на сайт</p>
        <a :href="`${info.link_href}`" >
          <span>{{ info.link_title }}</span>
          <img v-if="!info.darkMode" src="@/assets/images/btn__arrow__white.svg" alt="">
          <img v-else src="@/assets/images/btn__arrow__grey.svg" alt="">
        </a>
      </div>
    </div>
    <div v-if="!desktop" class="static__image__text dark"  >
      <p>Ссылка на сайт</p>
      <a :href="`${info.link_href}`">
        <span>{{ info.link_title }}</span>
        <img src="@/assets/images/btn__arrow__green.svg" alt="">
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: "StaticImage",
  props: ['info', "color"],
  data() {
    return {
      desktop: true
    }
  },
  computed:{
    colorText(){
      return '#000'
    }
  },
  methods: {
    checkWidth() {
      window.innerWidth <= 1100 ? this.desktop = false : this.desktop = true
    },
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style scoped lang="scss">
.static__image {
  &__background {
    position: relative;
    width: 100%;
    border-radius: 5px;
    @include disflex(flex, center, center);

    .image {
      padding: 0 174px;
      width: 100%;
      @media (max-width: 1920px) {
        @include adaptive-value("padding", 0, 174 0, 0, 174 0);
      }
    }


  }
  .static__image__text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 69px 74px;
    @media (max-width: 1920px) {
      @include adaptive-value("padding", 69 10, 74 10, 69 10, 74 10);
    }

    &.dark_color {
      a, p{
        color: #0E0252;
      }
    }

    &.dark{
      position: relative;
      padding: 24px 0 0;
      p {
        color: #0E0252;
      }
      a {
        color: #88BA4A;
      }
    }

    a, p {
      color: white;
      font-weight: 600;
    }

    p {
      font-size: 25px;
      line-height: 37px;
      margin: 0 0 16px;
      @media (max-width: 1920px) {
        @include adaptive-value("margin", 0, 0, 16 8, 0);
        @include adaptiv-fontAll(25, 18);
        @include adaptive-elem(line-height ,37, 23);
      }

    }

    a {
      @include disflex(flex, stretch, center);
      font-size: 18px;
      line-height: 23px;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(18, 15);
        @include adaptive-elem(line-height,23, 19);
      }

      span {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
