import { createSSRApp } from 'vue'
import { createWebHistory } from 'vue-router'
import createRouter from './router/index.js'
import App from './App.vue'
import createStore from "@/store";

const app = createSSRApp(App)

const router = createRouter(createWebHistory())
const store = createStore()


app.use(router)
app.use(store)

router.isReady().then(() => {
    app.mount('#app')
})