<template>
	<section class="slider__mobile">
		<h3 v-if="text" class="slider__text__h3">{{ projects[id].title }}</h3>


		<div style="position: relative" v-if="text">
			<div class="swiper-container" ref="sliderMobile">
				<div class="swiper-wrapper">
					<div class="swiper-slide"
					     v-for="image in projects"
					     :key="image.id"
					     :style="`background: ${image.color}`"
					>
						<img
							style="width: 100%"
							:style="`background: ${image.color}`"
							:src="require(`@/assets/images/projects/${image.image}`)"
							alt=""
						>
					</div>
				</div>
			</div>

			<div class="button__arrow " @click="next">
				<img src="@/assets/images/btn__arrow__white.svg" alt="">
			</div>
		</div>

		<div style="position: relative" v-else>
			<div class="swiper-container" ref="sliderMobile">
				<div class="swiper-wrapper">
					<div class="swiper-slide"
					     v-for="image in projects"
					     :key="image.id"
					     :style="`background: ${color}`"
					>
						<img
							style="width: 100%"
							:style="`background: ${image.color}`"
							:src="require(`@/assets/images/projects/${image.image}`)"
							alt=""
						>
					</div>
				</div>
			</div>

			<div class="button__arrow" @click="slider.slideNext()">
				<img src="@/assets/images/btn__arrow__white.svg" alt="">
			</div>
		</div>


		<ProjectText v-if="text" :projects="projects[id].description_project" :ProjectComponents="true"/>

		<div v-if="text" class="slider__buttons">
			<ButtonMove
				:text="`Смотреть проект`"
				:isWhite="true"
				:image="`arrows`"
				@click="$router.push({name: 'ProjectsId', params: {id: id + 1}})"
			/>
			<ButtonMove
				:text="`Смотреть все проекты`"
				:isWhite="false"
				:image="`arrows`"
				@click="$router.push({name: 'Projects'})"
			/>
		</div>
	</section>

</template>

<script>
import ButtonMove from "./ButtonMove";
import ProjectText from "./ProjectText";
import Swiper from 'swiper';

export default {
	name: "ImageSliderMobile",
	props: ['projects', 'color', 'text'],
	components: {ButtonMove, ProjectText},
	data() {
		return {
			id: 0,
			slider: null,
			mySliderOptions: {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 30
			},
		}
	},
	methods: {
		next() {
			this.slider.slideNext()
		},
	},
	mounted() {
		this.slider = new Swiper(this.$refs.sliderMobile, this.mySliderOptions)

		if (this.$props.text) {
			this.slider.on('slideChange', (e) => {
				this.id = e.realIndex
			})
		}
	}
}
</script>


<style scoped lang="scss">
@import "node_modules/swiper/swiper";
.slider__mobile {
	position: relative;
	width: calc(100% - 16px);

	h3 {
		font-weight: 600;
		font-size: 39px;
		line-height: 51px;

		@media (max-width: 1920px) {
			@include adaptiv-fontAll(39, 25);
			@include adaptive-elem(line-height, 51, 32);
		}
	}

	.swiper-container {
		margin: 16px 0 16px;
		position: relative;

		.swiper-slide {
			height: 700px;
			@media (max-width: 840px) {
				height: 600px;
			}
			@media (max-width: 780px) {
				height: 540px;
			}
			@media (max-width: 700px) {
				height: 500px;
			}
			@media (max-width: 660px) {
				height: 440px;
			}
			@media (max-width: 580px) {
				height: 400px;
			}
			@media (max-width: 500px) {
				height: 300px;
			}
			@media (max-width: 410px) {
				height: 260px;
			}
			@media (max-width: 340px) {
				height: 210px;
			}
			@include disflex(flex, center, center);

			img {
				width: 100%;
				height: inherit;
			}
		}
	}

	.slider__buttons {
		@include disflex(flex, stretch, center);
		margin: 64px 0 0;
		@media (max-width: 1920px) {
			@include adaptive-value("margin", 64 24, 0, 0, 0);
		}
		@media (max-width: 1530px) {
			display: inline-block;
		}
    @media (min-width: 580px) and (max-width: 976px) {
      display: flex;
    }

		.button__link {
			padding: 0 30px;

			&:first-of-type {
				margin: 0 32px 0 0;
				@media (max-width: 1530px) {
					margin: 0 0 32px;
				}
        @media (min-width: 580px) and (max-width: 976px) {
          margin: 0 24px 0 0;
        }
			}
		}
	}

	.button__arrow {
		position: absolute;
		right: -16px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		background: #88BA4A;
		@include disflex(flex, center, center);
		z-index: 5;

		img {
			width: 22px;
		}
	}
}

</style>
