<template>

  <section class="similar_products">
    <h2>Похожие проекты</h2>
    <div class="swiper-container similar_products__content" ref="simularSlider">
      <div class="swiper-wrapper products__item" >
        <div
            v-for="link in info"
            :key="link.id"
            class="products__item swiper-slide"
            @click="goToProject(link.id)"
        >
          <div class="products__item__image" :style="`background: ${link.color}`">
            <img :src="require(`@/assets/images/projects/${link.image}`)" alt="">
          </div>
          <h4>{{ link.title }}</h4>
        </div>

      </div>
    </div>

    <div class="button__arrows">
      <div class="button__arrow" @click="back">
        <img src="@/assets/images/slider__arrow__white.svg" alt="">
      </div>
      <div class="button__arrow" @click="next">
        <img src="@/assets/images/slider__arrow__white.svg" alt="">
      </div>
    </div>
  </section>


</template>

<script>
import Swiper from 'swiper'

export default {
  name: "Slider",
  props: ["info", "color"],
  computed: {
    styleForSlider() {
      return {
        background: this.info.color,
      }
    }
  },
  data() {
    return {
      slider: null,
      mySliderOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
    }
  },
  methods: {
    goToProject(id) {
      this.$router.push(`/projects/${id + 1}`)
    }
  },
  mounted() {
    this.slider = new Swiper(this.$refs.simularSlider, this.mySliderOptions)
  }
}
</script>

<style scoped lang="scss">
@import "node_modules/swiper/swiper";
.similar_products {

  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 53px;
    @media (max-width: 1920px) {
      @include adaptiv-fontAll(48, 25);
      @include adaptive-elem(line-height, 53, 32);
    }
  }

  .products__item {
    cursor: pointer;

    &__image {
      @include disflex(flex, center, center);
      border-radius: 5px;
      max-height: 500px;
      height: 100%;
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }

      @media (max-width: 1920px) {
        @include adaptive-elem(height, 500, 100);
      }
    }

    h4 {
      text-align: left;
      color: #0E0252;
      font-weight: 600;
      font-size: 31px;
      line-height: initial;
      margin: 16px 0 0;
      @media (max-width: 1920px) {
        @include adaptiv-fontAll(31, 22);
      }
    }
  }
}
</style>
