import axios from 'axios';

// let url = 'http://localhost:4000/sendMail';



export default {
    actions: {
        SEND__DATA__FORM({commit}, data) {
            commit("UPdATE__STATE__FORM")
            axios.post(window.location.origin + '/sendmail', {
                params: {
                    phone: data.phoneData,
                    message: data.messagesData
                }
            },)
                .then(() => {
                commit("UPdATE__STATE__FORM__SUCCESSFULLY")
            }).catch((error) => {
                alert(`Ошибка отправки данных: ${error}`)
            })
        },
        CLOSE__SUCCESSFULLY__INFO({commit}) {
            commit("UPDATE__CLOSE__SUCCESSFULLY__INFO")
        },
    },
    mutations: {
        UPdATE__STATE__FORM(state) {
            state.formSent = true
        },
        UPdATE__STATE__FORM__SUCCESSFULLY(state) {
            state.formSent = false
            state.formSentWindow = true
        },
        UPDATE__CLOSE__SUCCESSFULLY__INFO(state) {
            state.formSentWindow = false
            state.formSent = false
        }
    },
    state: () => ({
        formSent: false,
        formSentWindow: false
    }),
    getters: {
        FORM__SENT(state) {
            return state.formSent
        },
        FORM__SENT_WINDOW(state) {
            return state.formSentWindow
        }
    }
}
