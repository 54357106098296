<template>
  <div class="home">
    <MainSection v-cloak :links="MAIN_PAGE.main_section"/>
    <Projects :projects="PROJECTS_ALL"/>
    <Team :team="MAIN_PAGE.team"/>
    <Work :works="MAIN_PAGE.work"/>
    <Contacts :info="MAIN_PAGE.contacts"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MainSection from "@/views/home/v-main-section/MainSection";
import Projects from "@/views/home/v-projects/Projects";
import Team from "@/views/home/v-team/Team";
import Work from "@/views/home/v-work/Work";
import Contacts from "@/views/home/v-contacts/Contacts";

export default {
  name: 'Home',
  components: {
    MainSection,
    Projects,
    Team,
    Work,
    Contacts
  },
  computed: {...mapGetters(['MAIN_PAGE', 'PROJECTS_ALL'])}
}
</script>

<style scoped lang="scss">
.hone{
  min-height: 100vh;
}
</style>
