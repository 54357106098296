<template>
	<form>
		<div class="form__title">Есть проект для нас?</div>
		<p>
			Оставьте заявку и мы обсудим вашу задачу,
			подготовим индивидуальное коммерческое предложение и разработаем ваш проект.
		</p>
		<div class="form__item">
<!--			v-mask="'+7 (###) ### ####'"-->
			<input
				ref="phoneInput"
				type="tel"
				v-model.trim="form.phone"
				:class="resultValidatePhone"
				placeholder="Телефон*"
        maxlength="18"
			/>
			<ErrorValidate v-if="v$.form.phone.$dirty && !v$.form.phone.required.$response"
			               :error="`Обязательное поле`"/>
			<ErrorValidate v-if="v$.form.phone.$dirty && !v$.form.phone.minLength.$response"
			               :error="`Номер телефона введен некорректно`"/>
		</div>
		<div class="form__item">
      <textarea
	      name="messages"
	      v-model.trim="form.messages"
	      :class="resultValidateMessages"
	      placeholder="Сообщение"
      />
			<ErrorValidate v-if="v$.form.messages.$dirty && !v$.form.messages.required.$response"
			               :error="`Обязательное поле`"/>
			<ErrorValidate v-if="v$.form.messages.$dirty && !v$.form.messages.minLength.$response"
			               :error="`Здесь должно быть больше 5-ти символове`"/>
		</div>
		<ButtonMove
			:text="`Оставить заявку`"
			:isWhite="false"
			:image="`plus`"
			@click="onSubmit"
		/>

		<div class="agreement">Нажимая на кнопку, вы даете
			<router-link @click="closeReadyForm" :to="{name: 'Politic'}">согласие на обработку персональных данных.</router-link>
		</div>
		<img @click="CLOSE_POPUP_ORDER()" v-show="popup" class="close__form" src="@/assets/images/close.svg"
		     alt="Close">
		<Loader v-if="FORM__SENT" class="form_load" :class="{white: popup}"/>
		<div class="form_send_successfully" :class="{white: popup}" v-show="FORM__SENT_WINDOW">
			<img src="@/assets/images/form__send.svg" alt="">
			<h3>Данные успешно отправлены</h3>
			<p>В скором времени с вами свяжется менеджер</p>
			<img class="close" @click="closeReadyForm" src="@/assets/images/close.svg" alt="">
		</div>

	</form>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import useVuelidate from '@vuelidate/core'
import {required, minLength} from '@vuelidate/validators'
import ErrorValidate from "./ErrorValidate";
import ButtonMove from "./ButtonMove";
import Loader from "./Loader";

export default {
	components: {ErrorValidate, ButtonMove, Loader},
	name: 'Form',
	props: ['popup'],
	data() {
		return {
			form: {
				phone: '',
				messages: ''
			},
		}
	},
	computed: {
		...mapGetters(['FORM__SENT', 'FORM__SENT_WINDOW']),
		resultValidatePhone() {
			if (this.v$.form.phone.$error) return 'is-invalid'
			else if (!this.v$.form.phone.$error && this.v$.form.phone.$dirty) return 'is-valid'
			else return ''
		},
		resultValidateMessages() {
			if (this.v$.form.messages.$error) return 'is-invalid'
			else if (!this.v$.form.messages.$error && this.v$.form.messages.$dirty) return 'is-valid'
			else return ''
		}
	},
	setup() {
		return {v$: useVuelidate()}
	},
	validations() {
		return {
			form: {
				phone: {required, minLength: minLength(17)},
				messages: {required, minLength: minLength(5)}
			}
		}
	},
	watch: {
		formSentWindow(e) {
			this.v$.$reset()
			this.form.phone = ''
			this.form.messages = ''
		}
	},
	methods: {
		...mapActions(['CLOSE_POPUP_ORDER', 'SEND__DATA__FORM', 'CLOSE__SUCCESSFULLY__INFO']),
		onSubmit() {
			this.v$.form.$touch()
			if (!this.v$.form.$error) {
				let formData = {
					phoneData: this.form.phone,
					messagesData: this.form.messages
				}
				this.SEND__DATA__FORM(formData)
			}
		},
		closeReadyForm() {
			if (this.$props.popup) {
				this.CLOSE_POPUP_ORDER()
				this.CLOSE__SUCCESSFULLY__INFO()
			} else {
				this.CLOSE__SUCCESSFULLY__INFO()
			}
		},
    getInputNumbersValue(input){
      return input.value.replace(/\D/g, '');
    },
    onPhoneInput(e){
      let input = e.target,
          inputNumbersValue = this.getInputNumbersValue(input),
          selectionStart = input.selectionStart,
          formattedInputValue = "";

      if (!inputNumbersValue) {
        return input.value = "";
      }

      if (input.value.length !== selectionStart) {
        // Editing in the middle of input, not last symbol
        if (e.data && /\D/g.test(e.data)) {
          // Attempt to input non-numeric symbol
          input.value = inputNumbersValue;
        }
        return;
      }

      if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        if (inputNumbersValue[0] == "9") inputNumbersValue = "7" + inputNumbersValue;
        let firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
        formattedInputValue = input.value = firstSymbols + " ";
        if (inputNumbersValue.length > 1) {
          formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
        }
        if (inputNumbersValue.length >= 5) {
          formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
        }
        if (inputNumbersValue.length >= 8) {
          formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
        }
        if (inputNumbersValue.length >= 10) {
          formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
        }
      } else {
        return input.value = "";
      }
      input.value = formattedInputValue;
    }
	},
  mounted() {
    this.$refs.phoneInput.addEventListener('input', this.onPhoneInput, false);
  },
  beforeUnmount() {
    this.$refs.phoneInput.removeEventListener('input', this.onPhoneInput);
  }
}
</script>

<style scoped lang="scss">

.form_load {
	position: absolute;
	left: 0;
	top: 0;

	height: 100%;
	@include disflex(flex, center, center);
	background: #0E025260;

	&.white {
		background: #FFFFFF80;
	}
}

.form_send_successfully {
	background: #0E0252;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include disflex(flex, center, center);
	flex-direction: column;

	&.white {
		background: #FFFFFF;
	}

	img {
		width: auto;

	}

	h3 {
		margin: 32px 0 6px;
		font-weight: 600;
		font-size: 39px;
		line-height: 51px;
	}

	p {
		font-weight: normal;
		font-size: 18px;
		line-height: 28px;
	}

	.close {
		cursor: pointer;
		position: absolute;
		top: 30px;
		right: 35px;
	}
}

form {
	position: relative;
	background: #0E0252;
	border-radius: 5px;
	color: white;
	padding: 55px 144px;
	max-width: 706px;
	z-index: 1;
	border-radius: 5px;
	@media (max-width: 1920px) {
		@include adaptive-value("padding", 55 60, 144 20, 55 50, 144 20);
	}
	@media (max-width: 700px) {
		border-radius: 0;
	}


	.form__title {
		font-weight: 600;
		font-size: 39px;
		line-height: 51px;
		margin: 0 0 24px;
		@media (max-width: 1920px) {
			@include adaptiv-fontAll(39, 25);
			@include adaptive-elem(line-height, 51, 32);
			@include adaptive-value("margin", 0, 0, 24 16, 0);
		}
	}

	p {
		font-weight: normal;
		font-size: 18px;
		line-height: 28px;
		@media (max-width: 1920px) {
			@include adaptiv-fontAll(18, 16);
			@include adaptive-elem(line-height, 28, 24);
		}
	}

	.form__item {
		position: relative;

		input {
			height: 30px;
			margin: 32px 0 0;
		}

		textarea {
			margin: 48px 0 0;
			height: 58px;
			resize: none;
		}

		input, textarea {
			border-bottom: 1px solid white;
			font-weight: normal;
			font-size: 16px;
			line-height: 21px;
			background: transparent;
			display: block;
			width: 100%;
			color: #FFFFFF;
			padding: 0 0 6px;
			transition: .2s ease-in-out;

			&.is-valid {
				border-bottom: 1px solid #88BA4A;
			}

			&.is-invalid {
				border-bottom: 1px solid #E43F3F;
			}

			&::placeholder {
				color: #FFFFFF;
			}
		}
	}


	.button__link {
		justify-content: center;
		width: 100%;
		margin: 64px 0 24px;
	}

	.agreement {
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;

		a {
			border-bottom: 1px solid white;
			color: white;
		}
	}

	.close__form {
		cursor: pointer;
		position: absolute;
		top: 30px;
		right: 40px;
		@media (max-width: 540px) {
			right: 20px;
			top: 20px;
		}
	}
}
</style>
