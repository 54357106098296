<template>
  <section class="contacts" id="contacts">
    <div class="contacts__content">
      <h2 class="title__section">Контакты</h2>
      <div class="contacts__content__items">
        <Form v-if="desktop" :popup="false"/>
        <Info :info="info" />
      </div>
      <MapYandex v-if="desktop" />
    </div>
  </section>
</template>


<script>
import Form from "../../../components/Form";
import Info from "@/views/home/v-contacts/Info";
import MapYandex from "@/views/home/v-contacts/MapYandex";

export default {
  name: 'Contacts',
  components: {
    Form,
    Info,
    MapYandex
  },
  props: ['info'],
  data(){
    return{
      desktop: null
    }
  },
  methods: {
    checkWidth() {
      window.innerWidth <= 1200 ? this.desktop = false : this.desktop = true
    }
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style scoped lang="scss">

.contacts {
  color: #0E0252;

  &__content {
    position: relative;
    height: 1158px;
    padding: 60px 20px 0;
    @media (max-width: 1920px) {
      @include adaptive-value("padding", 60 30, 20, 0, 20);
    }
    @media (max-width: 1200px) {
      height: auto;
      margin: 0 0 38px;
    }
    &__items {
      max-width: 1554px;
      margin: 0 auto;

      @include disflex(flex, stretch, stretch);
      @media (max-width: 976px) {
        padding: 0;
      }
    }
  }
}
</style>
