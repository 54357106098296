<template>
  <footer>
    <div class="footer__content">

      <div v-if="!desktop" class="messages">
        <h2>Есть проект для нас?</h2>
        <p>
          Оставьте заявку и мы обсудим вашу задачу, подготовим индивидуальное коммерческое предложение и разработаем
          ваш проект.
        </p>

        <ButtonMove
            :text="`Оставить заявку`"
            :isWhite="false"
            :image="`plus`"
            @click="OPEN_POPUP_ORDER()"
        />


      </div>
      <div class="footer__logo">
        <router-link :to="{name: 'Home'}">
          <img src="@/assets/images/logoWhite.svg" alt="Logo">
        </router-link>
        <ButtonUp v-if="!desktop"/>
      </div>
      <p class="copy" v-if="desktop">© {{ getCopyrightYear() }} МЕТА</p>
      <router-link class="politic" :to="{name: 'Politic'}">Политика конфиденциальности</router-link>
      <p class="copy" v-if="!desktop">© {{ getCopyrightYear() }}, МЕТА</p>
      <ButtonUp v-if="desktop"/>
    </div>
  </footer>
</template>

<script>
import {mapActions} from "vuex";
import ButtonMove from "./ButtonMove";
import ButtonUp from "./ButtonUp";

export default {
  name: 'Footer',
  data() {
    return {
      desktop: null,
    }
  },
  components: {ButtonMove, ButtonUp},
  methods: {
    ...mapActions(['OPEN_POPUP_ORDER']),
    checkWidth() {
      window.innerWidth <= 976 ? this.desktop = false : this.desktop = true
    },
    getCopyrightYear(){
      return new Date().getFullYear()
    }
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>

<style scoped lang="scss">
footer {
  background: #0E0252;
  height: fit-content;

  .footer__content {
    position: relative;
    max-width: 1816px;
    margin: 0 auto;
    padding: 28px 20px;
    @include disflex(flex, stretch, center);
    @media (max-width: 1920px) {
      @include adaptive-value("padding", 28 30, 20, 28 30, 20);
    }
	  @media (max-width: 840px) {
		  @include adaptive-value("padding", 28 30, 20,78 , 20);
	  }
    @media (max-width: 976px) {
      display: block;
    }

    .messages {
      margin: 0 0 50px;

      h2 {
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;
        color: #FFFFFF;
      }

      p {
        margin: 16px 0 24px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }

      .button__link {
        max-width: 400px;
        width: 100%;
        justify-content: center;
      }
    }

    .footer__logo {
      @include disflex(flex, space-between, center);
      margin: 0 146px 0 0;
      @media (max-width: 1920px) {
        @include adaptive-value("margin", 0, 146 40, 0, 0);
      }
      @media (max-width: 976px) {
        margin: 0 0 16px;
      }

      .button__up {
        position: relative;
        top: auto;
        right: auto;
      }
    }


    a:nth-child(3), a:nth-child(4) {
      border-bottom: 1px solid white;
      @media (max-width: 976px) {
        border-bottom: none;
      }
    }

    a:nth-child(3), a:nth-child(4) {
      @media (max-width: 976px) {
        border-bottom: 1px solid white;
      }
    }

    a:nth-child(3) {
      margin: 0 48px;
      @media (max-width: 1920px) {
        @include adaptive-value("margin", 0, 48 24, 0, 48 24);
      }
      @media (max-width: 976px) {
        margin: 0 0 8px;
      }
    }

    a:last-child {
      margin: 0;
    }

    a, p {
      color: #FFFFFF;
      @media (max-width: 976px) {
        display: block;
        width: fit-content;
        margin: 0 0 8px;
      }
    }

    p {
      @media (max-width: 976px) {
        margin: 0;
      }
    }

    .button__link {
      display: flex;
      border-bottom: none !important;

      p {
        margin: 0 16px 0 0;
      }
    }

  }
}
</style>
