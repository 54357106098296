<template>
  <transition name="fade">
    <section class="loader__container">
      <div class="loader"></div>
    </section>

  </transition>
</template>

<script>
export default {
  name: "Loader",
  props: ['height', 'width'],
}
</script>

<style scoped lang="scss">

.loader__container{
  width: 100%;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(87, 60, 73, 0.2);
  border-right: 1.1em solid rgba(87, 60, 73, 0.2);
  border-bottom: 1.1em solid rgba(87, 60, 73, 0.2);
  border-left: 1.1em solid #88ba4a;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

</style>

<style>
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>