export default {
    actions: {},
    mutations: {},
    state: () => ({
        main_page:{
            main_section: {
                video: "main.svg",
                linksForMainSection: [
                    {
                        text: "Dribble",
                        href: "https://dribbble.com/wearemetastudio"
                    },
                    {
                        text: "Behance",
                        href: "https://www.behance.net/metameta4"
                    }
                ],
            },
            work: [
                {
                    id: 0,
                    title: "Аналитика и проектирование",
                    text: "Погружаемся в задачу, генерируем идеи по оптимизации и улучшению проекта, систематизируем и структурируем информацию. Создаём прототип проекта. Выбираем технологии и инструменты для эффективного решения конкретных задач проекта."
                },
                {
                    id: 1,
                    title: "Дизайн UX/UI",
                    text: "Разрабатываем дизайн-систему для проекта. Все макеты разрабатываются с учетом адаптации под мобильные устройства в 3х вариантах: Desktop, Tablet, Mobile."
                },
                {
                    id: 2,
                    title: "Адаптивная вёрстка",
                    text: "Верстаем разработанные макеты. Тестируем на мобильных устройствах."
                },
                {
                    id: 3,
                    title: "Программирование",
                    text: "Программируем свёрстанные разделы в соответствии с планом-графиком. Подключаем платежные системы, модуль email-рассылки, интегрируем sms-шлюз.\n" +
                        "Ваш сервис выдержит тысячи единовременных обращений пользователей."
                },

            ],
            projects: [
                {
                    id: 0,
                    title: "Кратэк — сайт котельной компании",
                    color: "#6051B0",
                    image: "1.png",
                    task: "1 У компании есть онлайн-сервис по бронированию путёвок. Перед нами встала задача его доработки: исправить баги, сделать его адаптивным и внедрить дополнительный функционал.",
                    decision: "Провели аудит сервиса, выявили баги на фронтенде и исправили их, сделали рефакторинг кодовой базы. Адаптировали вёрстку под экраны настольных компьютеров и планшетов, а для пользователей смартфонов сделали мобильную версию сайта. Разработали фильтр путёвок, валидацию форм на сайте и «бесконечный скролл» на странице туров."
                },
                {
                    id: 1,
                    title: "Урарту — ресторан высокой кухни",
                    color: "#88BA4A",
                    image: "2.png",
                    task: "2 У компании есть онлайн-сервис по бронированию путёвок. Перед нами встала задача его доработки: исправить баги, сделать его адаптивным и внедрить дополнительный функционал.",
                    decision: "Провели аудит сервиса, выявили баги на фронтенде и исправили их, сделали рефакторинг кодовой базы. Адаптировали вёрстку под экраны настольных компьютеров и планшетов, а для пользователей смартфонов сделали мобильную версию сайта. Разработали фильтр путёвок, валидацию форм на сайте и «бесконечный скролл» на странице туров."
                },
                {
                    id: 2,
                    title: "Ремикс — магазин ювелирных изделий",
                    color: "#DCE5FF",
                    image: "3.png",
                    task: "3 У компании есть онлайн-сервис по бронированию путёвок. Перед нами встала задача его доработки: исправить баги, сделать его адаптивным и внедрить дополнительный функционал.",
                    decision: "Провели аудит сервиса, выявили баги на фронтенде и исправили их, сделали рефакторинг кодовой базы. Адаптировали вёрстку под экраны настольных компьютеров и планшетов, а для пользователей смартфонов сделали мобильную версию сайта. Разработали фильтр путёвок, валидацию форм на сайте и «бесконечный скролл» на странице туров."
                },
                {
                    id: 3,
                    title: "ЦСП — центр спортивной подготовки",
                    color: "#0E0252",
                    image: "4.png",
                    task: "4 У компании есть онлайн-сервис по бронированию путёвок. Перед нами встала задача его доработки: исправить баги, сделать его адаптивным и внедрить дополнительный функционал.",
                    decision: "Провели аудит сервиса, выявили баги на фронтенде и исправили их, сделали рефакторинг кодовой базы. Адаптировали вёрстку под экраны настольных компьютеров и планшетов, а для пользователей смартфонов сделали мобильную версию сайта. Разработали фильтр путёвок, валидацию форм на сайте и «бесконечный скролл» на странице туров."
                },
            ],
            technologies:{
                stack: [
                    {
                        image: "laravel.svg",
                        text: "Laravel"
                    },
                    {
                        image: "MySQLl.svg",
                        text: "MySQL"
                    },
                    {
                        image: "Vue.svg",
                        text: "Vue.js"
                    },
                ],
                description: "Мы используем для написания серверной части PHP-фреймворк Laravel, который отлично показывает себя в тысячах проектов по всему миру, включая highload-проекты. <br><br> В зависимости от сложности и специфики задач в качестве баз данных мы используем MySQL или MongoDB. <br><br> Клиентскую часть приложений реализует фронтенд-отдел опытных специалистов с использованием одного из самых популярных фреймворков Vue.js."
            },
            team: {
                title: " Наша страсть — создание нетиповых веб-приложений и сайтов. Любим технически сложные проекты, не боимся высоких нагрузок и не бросаем проекты на полпути.",
                text: "С 2001 года занимаемся разработкой сложных высоконагруженных веб-проектов, которые окупают затраты и приносят прибыль. <br> <br> Мы слышим клиента и формируем решения, которые одновременно решают задачи всех бизнес-юнитов заказчика, не теряя пользу для конечных клиентов. <br> Проводим качественные и количественные исследования, чтобы лучше понять ваш продукт и его пользователей и предложить решения на долгую перспективу."
            },
            contacts: {
                links:[
                    {
                        text: "WhatsApp",
                        href: "https://wa.me/+79832828750"
                    },
                    {
                        text: "Telegram",
                        href: "https://t.me/Graffiti68"
                    },
                    {
                        text: "Dribble",
                        href: "https://dribbble.com/wearemetastudio"
                    },
                    {
                        text: "Behance",
                        href: "https://www.behance.net/metameta4"
                    }
                ],
                company:{
                    phone: "+73912147544",
                    phoneForClient: "+7 (391) 214-75-44",
                    email: "info@meta.studio",
                    address: "г. Красноярск, ул. Калинина, 53а стр. 19"
                }
            },
        }
    }),
    getters: {
        MAIN_PAGE(state) {
            return state.main_page
        }
    }
}
