export default {
	actions: {},
	mutations: {},
	state: () => ({
		projects: [
			{
				id: 0,
				color: "#6051B0",
				video: "kratjek/video.png",
				title: "Кратэк — сайт поставщика отопительного оборудования",
				image: "kratjek/1.png",
				image_main_slider: "kratjek/1.png",
				static_image: {
					link_title: "kratjek.ru",
					link_href: "http://kratjek.ru/",
					image: "kratjek/static__image.png",
					darkMode: false
				},
				sphere_activities: {
					value: 1,
					text: "Товары"
				},
				description_project: {
					task: "Разработка сайта-презентации технически сложного продукта. Необходимо показать все важные детали и создать эффект высокотехнологичного производства.",
					decision: "В проекте используем концепцию Motion дизайна. Главная страница сайта реализована как классический лендинг, но при этом сайт многостраничный. Также были интегрированы виртуальные туры по реализованным объектам и несколько различных онлайн-калькуляторов.",
					technologies: "PHP    JavaScript    HTML5    CSS3    Sass    Gulp    BEM"
				},
				slider_desktop: [
					{
						id: 0,
						image: "kratjek/desk1.png"
					},
					{
						id: 1,
						image: "kratjek/desk2.png"
					},
					{
						id: 2,
						image: "kratjek/desk3.png"
					},
					{
						id: 3,
						image: "kratjek/desk4.png"
					}
				],
				slider_mobile: [
					{
						id: 0,
						image: "kratjek/mob1.png"
					},
					{
						id: 1,
						image: "kratjek/mob2.png"
					},
					{
						id: 2,
						image: "kratjek/mob3.png"
					},
					{
						id: 3,
						image: "kratjek/mob4.png"
					}
				],
				another_projects: [
					{
						id: 0,
						image: "kratjek/1.png",
						color: "#6051B0",
						title: "Кратэк — сайт котельной компании"
					},
					{
						id: 1,
						image: "yratry/1.png",
						color: "#88BA4A",
						title: "Урарту — ресторан высокой кухни"
					}
				]
			},
			{
				id: 1,
				color: "#88BA4A",
				video: "yratry/video.png",
				title: "Урарту — ресторан европейской кухни",
				image: "yratry/1.png",
				image_main_slider: "yratry/1.png",
				static_image: {
					link_title: "urartu24.ru",
					link_href: "https://urartu24.ru/",
					image: "yratry/static__image.png",
					darkMode: false
				},
				sphere_activities: {
					value: 2,
					text: "Рестораны"
				},
				description_project: {
					task: "Отображение премиум статуса и уюта ресторана. Сайт должен иметь эффектный дизайн, но при этом быть простым и понятным для пользователя.",
					decision: "Цель заказчика была достигнута преимущественно через дизайн-решения: эффектная видео-заставка с минималистичным меню, акцидентные шрифты и темная гамма, качественные и сочные фото блюд, вызывающие аппетит. При переходе на пользовательскую часть каталога дизайн становится неброским, акцентируя внимание пользователя на блюдах. Также была реализована система приема заказов для доставки и интегрирована бонусная система.",
					technologies: "PHP    JavaScript    HTML5    CSS3    Sass    Gulp    BEM"
				},
				slider_desktop: [
					{
						id: 0,
						image: "yratry/desk1.png"
					},
					{
						id: 1,
						image: "yratry/desk2.png"
					},
					{
						id: 2,
						image: "yratry/desk3.png"
					},
					{
						id: 3,
						image: "yratry/desk4.png"
					}
				],
				slider_mobile: [
					{
						id: 0,
						image: "yratry/mob1.png"
					},
					{
						id: 1,
						image: "yratry/mob2.png"
					},
					{
						id: 2,
						image: "yratry/mob3.png"
					},
					{
						id: 3,
						image: "yratry/mob4.png"
					}
				],
				another_projects: [
					{
						id: 0,
						image: "kratjek/1.png",
						color: "#6051B0",
						title: "Кратэк — сайт котельной компании"
					},
					{
						id: 1,
						image: "yratry/1.png",
						color: "#88BA4A",
						title: "Урарту — ресторан высокой кухни"
					}
				]
			},
			{
				id: 2,
				color: "#0E0252",
				video: "kras-csp/video.png",
				title: "ЦСП — центр спортивной подготовки",
				image: "kras-csp/1.png",
				image_main_slider: "kras-csp/1.png",
				static_image: {
					link_title: "krascsp.ru",
					link_href: "https://www.krascsp.ru/",
					image: "kras-csp/static__image.png",
					darkMode: false
				},
				sphere_activities: {
					value: 3,
					text: "Информационный портал"
				},
				description_project: {
					task: "Реализация спортивного информационного портала. Объединение в себе анонсов мероприятий, инфоповодов и системы обязательной отчетности.",
					decision: "Сайт представляет собой динамичный и анимированный информационный портал. Сочетание спортивного дизайна и быстрой анимации отражает ключевое направление деятельности организации. Сайт содержит каталоги событий, инфоповодов, спортивных объектов и многое другое. Был добавлен удобный поиск по архивам, а также страницы отчетности и выкладки документации в публичный доступ согласно требованиям в рамках деятельности.",
					technologies: "PHP    JavaScript    HTML5    CSS3    Sass    Gulp    BEM"
				},
				slider_desktop: [
					{
						id: 0,
						image: "kras-csp/desk1.png"
					},
					{
						id: 1,
						image: "kras-csp/desk2.png"
					},
					{
						id: 2,
						image: "kras-csp/desk3.png"
					},
					{
						id: 3,
						image: "kras-csp/desk4.png"
					}
				],
				slider_mobile: [
					{
						id: 0,
						image: "kras-csp/mob1.png"
					},
					{
						id: 1,
						image: "kras-csp/mob2.png"
					},
					{
						id: 2,
						image: "kras-csp/mob3.png"
					},
					{
						id: 3,
						image: "kras-csp/mob4.png"
					}
				],
				another_projects: [
					{
						id: 0,
						image: "kratjek/1.png",
						color: "#6051B0",
						title: "Кратэк — сайт котельной компании"
					},
					{
						id: 1,
						image: "yratry/1.png",
						color: "#88BA4A",
						title: "Урарту — ресторан высокой кухни"
					}
				]
			},
			{
				id: 3,
				color: "#0E0252",
				video: "real-media/video.png",
				title: "Реалмедиа — рекламное агентство",
				image: "real-media/1.png",
				image_main_slider: "real-media/1.png",
				static_image: {
					link_title: "rareal-media.ru",
					link_href: "http://real-media.ru/",
					image: "real-media/static__image.png",
					darkMode: false
				},
				sphere_activities: {
					value: 4,
					text: "Реклама"
				},
				description_project: {
					task: "Представление информации о имеющихся рекламных поверхностях и сопутствующих услугах компании в удобном формате и с современным дизайном. Создание делового имиджа. Понятный и простой доступ к базе рекламных поверхностей для размещения.",
					decision: "Сайт рекламного агентства должен соответствовать современным требованиям пользователей. Сочетание цветов и элементов отвечают минималистичному дизайну и передают настроение основного вида деятельности - типографии. Главный элемент — интерактивная карта имеющихся рекламных поверхностей, которая работает как каталог услуг. После выбора поверхности отображается информация о ней: адрес, размер и цена, а также возможность оформить заказ онлайн.",
					technologies: "PHP    JavaScript    HTML5    CSS3    Sass    Gulp    BEM"
				},
				slider_desktop: [
					{
						id: 0,
						image: "real-media/desk1.png"
					},
					{
						id: 1,
						image: "real-media/desk2.png"
					},
					{
						id: 2,
						image: "real-media/desk3.png"
					},
					{
						id: 3,
						image: "real-media/desk4.png"
					}
				],
				slider_mobile: [
					{
						id: 0,
						image: "real-media/mob1.png"
					},
					{
						id: 1,
						image: "real-media/mob2.png"
					},
					{
						id: 2,
						image: "real-media/mob3.png"
					},
					{
						id: 3,
						image: "real-media/mob4.png"
					}
				],
				another_projects: [
					{
						id: 0,
						image: "kratjek/1.png",
						color: "#6051B0",
						title: "Кратэк — сайт котельной компании"
					},
					{
						id: 1,
						image: "yratry/1.png",
						color: "#88BA4A",
						title: "Урарту — ресторан высокой кухни"
					}
				]
			},
			{
				id: 4,
				color: "#8D9BC2",
				video: "enginestore/video.png",
				title: "Домашние котельные — инженерный онлайн-магазин",
				image: "enginestore/1.png",
				image_main_slider: "enginestore/1.png",
				static_image: {
					link_title: "enginestore.ru",
					link_href: "http://enginestore.ru/",
					image: "enginestore/static__image.png",
					darkMode: false
				},
				sphere_activities: {
					value: 1,
					text: "Товары"
				},
				description_project: {
					task: "Разработка онлайн-магазина по продаже инженерного оборудования. Ключевая задача — совмещение функционала для розничного и оптового покупателя. Использовать магазин для прямых продаж и для сбора спецификаций в рамках комплектации строительного объекта или оптовой закупки дистрибьютора.",
					decision: "Реализована синхронизация с базой 1С. Добавлена возможность создавать наборы товаров для продажи одной позицией как «Готовые решения». Разработан конфигуратор фильтров и их типов для гибкой настройки, а также модуль Preset для создания подборок товаров в комбинации фильтров. Добавлена гибкая настройка скидочной системы в рамках групповых и персональных условий по пользователям. Использована технология Интернет-эквайринга — онлайн-оплата с помощью банковской карты. На данный момент проект находится на поддержке по пакету Fulltime и Digital Маркетингу.",
					technologies: "PHP    JavaScript    HTML5    CSS3    Sass    Gulp    BEM"
				},
				slider_desktop: [
					{
						id: 0,
						image: "enginestore/desk1.png"
					},
					{
						id: 1,
						image: "enginestore/desk2.png"
					},
					{
						id: 2,
						image: "enginestore/desk3.png"
					},
					{
						id: 3,
						image: "enginestore/desk4.png"
					}
				],
				slider_mobile: [
					{
						id: 0,
						image: "enginestore/mob1.png"
					},
					{
						id: 1,
						image: "enginestore/mob2.png"
					},
					{
						id: 2,
						image: "enginestore/mob3.png"
					},
					{
						id: 3,
						image: "enginestore/mob4.png"
					}
				],
				another_projects: [
					{
						id: 0,
						image: "kratjek/1.png",
						color: "#6051B0",
						title: "Кратэк — сайт котельной компании"
					},
					{
						id: 1,
						image: "yratry/1.png",
						color: "#88BA4A",
						title: "Урарту — ресторан высокой кухни"
					}
				]
			},
			{
				id: 5,
				color: "#8D9BC2",
				video: "three-hearts/video.png",
				title: "Три сердца — современный центр репродуктивной медицины",
				image: "three-hearts/1.png",
				image_main_slider: "three-hearts/1.png",
				static_image: {
					link_title: "3serdca.ru",
					link_href: "https://www.3serdca.ru/",
					image: "three-hearts/static__image.png",
					darkMode: false
				},
				sphere_activities: {
					value: 5,
					text: "Медицина"
				},
				description_project: {
					task: "Основная задача объединить три разные клиники в один сайт для организации просмотра спектра услуг и занятости специалистов.",
					decision: "Для удобства был добавлен фильтр для распределения услуг по каждой из клиник. Акции, услуги, специалисты и публикации объединены в единую систему, где каждый элемент системы можно привязать к другим. Возможно любое множество комбинаций из указанных четырех разделов сайта. Например, к акции можно привязать услуги и специалистов. На сайте присутствует версия для слабовидящих, выполненная по ГОСТ, что является важным пунктом для сайта медицинского учреждения. Пользователю предоставляется график работы специалистов и возможность онлайн-записи. Через личную страницу врача можно просмотреть его расписание и выбрать свободную дату для приема.",
					technologies: "PHP    JavaScript    HTML5    CSS3    Sass    Gulp    BEM"
				},
				slider_desktop: [
					{
						id: 0,
						image: "three-hearts/desk1.png"
					},
					{
						id: 1,
						image: "three-hearts/desk2.png"
					},
					{
						id: 2,
						image: "three-hearts/desk3.png"
					},
					{
						id: 3,
						image: "three-hearts/desk4.png"
					}
				],
				slider_mobile: [
					{
						id: 0,
						image: "three-hearts/mob1.png"
					},
					{
						id: 1,
						image: "three-hearts/mob2.png"
					},
					{
						id: 2,
						image: "three-hearts/mob3.png"
					},
					{
						id: 3,
						image: "three-hearts/mob4.png"
					}
				],
				another_projects: [
					{
						id: 0,
						image: "kratjek/1.png",
						color: "#6051B0",
						title: "Кратэк — сайт котельной компании"
					},
					{
						id: 1,
						image: "yratry/1.png",
						color: "#88BA4A",
						title: "Урарту — ресторан высокой кухни"
					}
				]
			},
			{
				id: 6,
				color: "#0E0252",
				video: "clinic-north/video.png",
				title: "Сеть многопрофильного медицинского учреждения",
				image: "clinic-north/1.png",
				image_main_slider: "clinic-north/1.png",
				static_image: {
					link_title: "clinsev.ru",
					link_href: "https://clinsev.ru/",
					image: "clinic-north/static__image.png",
					darkMode: false
				},
				sphere_activities: {
					value: 5,
					text: "Медицина"
				},
				description_project: {
					task: "Создать сайт сети клиник с широким спектром специалистов. Основная задача — облегчить поиск специалиста для решения проблемы пациента.",
					decision: "Цель заказчика была достигнута через добавление умного поиска по простым запросам клиентов. После описания проблемы своими словами или указания части тела происходит подбор услуг, врачей, акций клиники, информационных публикаций, отзывов и элементов раздела вопрос-ответ. Сайт содержит интерактивный элемент в виде тела человека, на котором можно указать проблемную зону и определить, к какому специалисту требуется обращаться. Также пользователю предоставляется личный кабинет с онлайн записью с помощью CTA формы. Через личную страницу врача можно просмотреть информацию о нем: образование, повышение квалификации, лицензии и сертификаты и многое другое, а также задать вопрос и написать отзыв.",
					technologies: "PHP    JavaScript    HTML5    CSS3    Sass    Gulp    BEM"
				},
				slider_desktop: [
					{
						id: 0,
						image: "clinic-north/desk1.png"
					},
					{
						id: 1,
						image: "clinic-north/desk2.png"
					},
					{
						id: 2,
						image: "clinic-north/desk3.png"
					},
					{
						id: 3,
						image: "clinic-north/desk4.png"
					}
				],
				slider_mobile: [
					{
						id: 0,
						image: "clinic-north/mob1.png"
					},
					{
						id: 1,
						image: "clinic-north/mob2.png"
					},
					{
						id: 2,
						image: "clinic-north/mob3.png"
					},
					{
						id: 3,
						image: "clinic-north/mob4.png"
					}
				],
				another_projects: [
					{
						id: 0,
						image: "kratjek/1.png",
						color: "#6051B0",
						title: "Кратэк — сайт котельной компании"
					},
					{
						id: 1,
						image: "yratry/1.png",
						color: "#88BA4A",
						title: "Урарту — ресторан высокой кухни"
					}
				]
			},
		]

	}),
	getters: {
		PROJECTS_ALL(state) {
			return state.projects
		},
	}
}
